import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { ReturnValue } from 'src/domains/sportsbook/betting/ui/betSlip/betslipCombination/BetslipCombination.style';
import {
    BetslipYouDecideRabItemsWrapper,
    RabItems,
    BetslipYouDecideEventReturn,
    BetslipYouDecideInputWrapper,
    BetslipYouDecideReturn,
    RabItemsWrapper,
    RabItemsWrapperSelection,
    BetslipYouDecideEventSuspendedBets,
    RabItemsWrapperMarket,
    ReturnsStyle,
    ReturnWrapper,
} from './BetslipYouDecide.style';
import { useCommon } from 'src/domains/common/Common';
import { RewardWalletIcon } from 'src/domains/layouts/shared/assets/iconography/betslip/RewardWallet';
import {
    AppliedFreeBetContainer,
    BonusButton,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.style';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

interface PropsType {
    rabSelectionId: BetslipRabId;
    className?: string;
}

export const BetslipYouDecideItem = observer('BetslipYouDecideItem', (props: PropsType) => {
    const common = useCommon();
    const { money } = BasicDataModel.get(common);
    const appState = useAppStateContext();
    const { className, rabSelectionId } = props;
    const {
        getSelectionAppliedBonuses,
        openBonusPopup,
        betSlipSummaryState: { betTooHighValue },
        possibleBetsRequestState: { coreRabPossibleBetsResponse },
    } = appState.appSportsBookState.betSlipState;
    const model = rabSelectionId.getModel();

    const input = <StakeInputMobx selectionId={model.id} />;

    const rabPrice = model.rabPrice;
    const isStake = model.inputField.isGreaterThanZero();
    const returnRABprice = model.rabStatus.price === null ? null : rabPrice.multiply(model.rabStatus.price);
    const items = model.rabOffer;
    const isBetWithoutOffer = items.length === 0;
    const setRef = appState.appSportsBookState.betSlipState.betslipData.setRef(rabSelectionId);
    const freeBets = model.getFreeBets(coreRabPossibleBetsResponse);
    const appliedBonusFreeBetAmount = getSelectionAppliedBonuses(model.correlationId);

    return (
        <>
            {
                <div className={className}>
                    <BetslipYouDecideRabItemsWrapper>
                        <RabItems>
                            {items.map((item, id) => {
                                return (
                                    <RabItemsWrapper key={id}>
                                        <RabItemsWrapperSelection data-test='selection-name'>
                                            {item.selection}
                                        </RabItemsWrapperSelection>
                                        <RabItemsWrapperMarket data-test='market-name'>
                                            {item.market}
                                        </RabItemsWrapperMarket>
                                    </RabItemsWrapper>
                                );
                            })}
                        </RabItems>
                    </BetslipYouDecideRabItemsWrapper>
                </div>
            }
            <BetslipYouDecideInputWrapper ref={setRef}>
                {input}
                {common.features.bonuseEngine && freeBets.length > 0 && (
                    <BonusButton
                        size='medium'
                        data-test='rab-bets-bonus-button'
                        onClick={(): Promise<void> => openBonusPopup(model.correlationId, freeBets)}
                    >
                        <RewardWalletIcon />
                    </BonusButton>
                )}
            </BetslipYouDecideInputWrapper>
            {common.features.bonuseEngine && appliedBonusFreeBetAmount !== null && (
                <AppliedFreeBetContainer>
                    <RewardWalletIcon /> {money(appliedBonusFreeBetAmount)} Free Bet Applied
                </AppliedFreeBetContainer>
            )}

            {model.rabStatus.suspendedBets || isBetWithoutOffer ? (
                <BetslipYouDecideEventSuspendedBets>
                    That selection is no longer available. Please resolve to continue.
                </BetslipYouDecideEventSuspendedBets>
            ) : (
                <ReturnWrapper data-test='returns'>
                    <BetslipYouDecideEventReturn>
                        <I18n
                            langKey='betslip.you-decide.returns'
                            defaultText='Returns:'
                        />
                        <BetslipYouDecideReturn>
                            {' '}
                            {returnRABprice === null ? null : money(returnRABprice)}
                        </BetslipYouDecideReturn>
                    </BetslipYouDecideEventReturn>
                    {betTooHighValue !== null && isStake ? (
                        <ReturnsStyle>
                            <I18n
                                langKey='betslip.max-bet.label'
                                defaultText='Max. bet:'
                            />

                            <ReturnValue>{money(betTooHighValue)}</ReturnValue>
                        </ReturnsStyle>
                    ) : null}
                </ReturnWrapper>
            )}
        </>
    );
});
