import { CriteriaType } from 'src_server/trpc/types/payments/getTransactions';
import { TransactionType } from './monthlyActivityPopup.state';

export const pickCriteria = (
    customerId: number,
    transactionType: TransactionType,
    startDate: string,
    endDate: string
): CriteriaType[] => {
    if (transactionType === 'deposit') {
        return [
            {
                field: 'wallet.id',
                type: 'eq',
                value: customerId.toString(),
            },
            {
                field: 'status',
                type: 'eq',
                value: 'paid',
            },
            {
                field: 'tags.accountAdjustment',
                type: 'ne',
                value: true,
            },
            {
                field: 'type',
                type: 'eq',
                value: transactionType,
            },
            {
                field: 'createdAt',
                type: 'lt',
                value: endDate,
            },
            {
                field: 'createdAt',
                type: 'gt',
                value: startDate,
            },
        ];
    }

    if (transactionType === 'withdrawal') {
        return [
            {
                field: 'wallet.id',
                type: 'eq',
                value: customerId.toString(),
            },
            {
                field: 'status',
                type: 'in',
                value: ['paid', 'requested', 'assigned'],
            },
            {
                field: 'tags.accountAdjustment',
                type: 'ne',
                value: true,
            },
            {
                field: 'type',
                type: 'eq',
                value: transactionType,
            },
            {
                field: 'createdAt',
                type: 'lt',
                value: endDate,
            },
            {
                field: 'createdAt',
                type: 'gt',
                value: startDate,
            },
        ];
    }

    return [
        {
            field: 'wallet.id',
            type: 'eq',
            value: customerId.toString(),
        },
        {
            field: 'status',
            type: 'eq',
            value: 'paid',
        },
        {
            field: 'type',
            type: 'eq',
            value: 'withdrawal',
        },
        {
            field: 'tags.adjustmentType',
            type: 'in',
            value: ['bank-transfer', 'cash', 'cheque'],
        },
        {
            field: 'tags.tradingAdjustment',
            type: 'eq',
            value: false,
        },
        {
            field: 'createdAt',
            type: 'lt',
            value: endDate,
        },
        {
            field: 'createdAt',
            type: 'gt',
            value: startDate,
        },
    ];
};
