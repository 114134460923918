import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Slider } from 'src/domains/casino/webview/CasinoGames/home-games/slider/Slider';
import { GridWrapper } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesHome.style';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { SingleGameExt } from 'src/domains/casino/webview/CasinoGames/single-game-ext/SingleGameExt';

interface GamesGridPropsType {
    appCasinoState: AppCasinoState;
    title: string | JSX.Element;
    gameModels: Array<CasinoGameModelExt>;
    onViewAllClick?: () => void;
}

export const GamesGridCasino = observer('GamesGridCasino', (props: GamesGridPropsType) => {
    const { appCasinoState, gameModels, title, onViewAllClick } = props;

    const isLargeMobile = appCasinoState.breakpointsState.largeMobile.isBiggerOrEq === true;
    const gameModelsSlice = isLargeMobile ? gameModels : gameModels.slice(0, 17);

    if (gameModelsSlice.length === 0) {
        return null;
    }

    return (
        <Slider
            appCasinoState={appCasinoState}
            title={title}
            onViewAllClick={onViewAllClick}
        >
            <GridWrapper>
                {gameModelsSlice.map((gameModel, idx) => {
                    return (
                        <SingleGameExt
                            appCasinoState={appCasinoState}
                            key={gameModel.id}
                            isFirstTile={idx === 0}
                            gameModel={gameModel}
                            wrapperType='slider-grid'
                        />
                    );
                })}
            </GridWrapper>
        </Slider>
    );
});
