import { action, computed, observable, makeObservable } from 'mobx';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { UsersState } from 'src/domains/players/state/UsersState';

type PageType = 'help' | 'terms-and-conditions' | 'privacy-policy';

export class CmsDrawerPagesState {
    @observable public displayPage: boolean = false;
    @observable public pageType: PageType | null = null;

    public constructor(
        private readonly usersState: UsersState,
        private readonly customKeyboard: CustomKeyboardState
    ) {
        makeObservable(this);
    }

    @action private showPage = (pageType: PageType): void => {
        this.pageType = pageType;
        this.displayPage = true;

        if (this.customKeyboard.asideElement !== null) {
            this.customKeyboard.asideElement.scrollTo(0, 0);
        }
    };

    @action public showHelpPage = (): void => {
        this.showPage('help');
    };

    @action public showBonusTermsPage = (): void => {
        this.showPage('help');
    };

    @action public showPrivacyPolicyPage = (): void => {
        this.showPage('privacy-policy');
    };

    @action public showTermsAndConditionsPage = (): void => {
        this.showPage('terms-and-conditions');
    };

    @computed public get pageData(): { title: string | null; content: string | null } | null {
        if (this.pageType === null) {
            return null;
        }
        const pageResource = this.usersState.getPage(this.pageType);

        if (pageResource.type === 'ready') {
            return {
                title: pageResource.value?.title ?? null,
                content: pageResource.value?.content ?? null,
            };
        }

        return null;
    }

    @computed public get title(): string | null {
        return this.pageData?.title ?? null;
    }

    @computed public get content(): string | null {
        return this.pageData?.content ?? null;
    }

    @action public closePage = (): void => {
        this.displayPage = false;
        this.pageType = null;
    };
}
