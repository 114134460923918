import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon8 = observer('NumberIcon8', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 105 128'

            width='105'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M52.3999 4C60.856 4 68.2552 5.33519 74.5973 8.00556C81.0508 10.6759 86.0021 14.459 89.4513 19.3547C92.9005 24.1391 94.6252 29.758 94.6252 36.2114C94.6252 40.8846 93.4569 45.3908 91.1203 49.7302C88.7837 53.9583 85.6683 57.3519 81.774 59.911C87.5598 62.8039 92.1217 66.9207 95.4596 72.2615C98.9089 77.491 100.634 83.2211 100.634 89.452C100.634 96.3505 98.6307 102.414 94.6252 107.644C90.7309 112.873 85.1676 116.935 77.9353 119.828C70.703 122.609 62.2469 124 52.5668 124C42.9979 124 34.5417 122.609 27.1982 119.828C19.9659 117.046 14.347 113.096 10.3414 107.978C6.44715 102.86 4.5 96.9068 4.5 90.1196C4.5 83.7775 6.33588 77.8804 10.0076 72.4284C13.7907 66.9764 18.7976 62.8039 25.0285 59.911C20.6891 57.0181 17.1843 53.3463 14.5139 48.8957C11.9548 44.4451 10.6752 39.8832 10.6752 35.21C10.6752 28.9791 12.3999 23.5271 15.8491 18.854C19.2983 14.0695 24.1384 10.3978 30.3693 7.83866C36.7114 5.27955 44.0549 4 52.3999 4ZM52.5668 26.6982C48.3387 26.6982 45.0563 27.6996 42.7197 29.7024C40.4944 31.5939 39.3818 34.3755 39.3818 38.0473C39.3818 41.4965 40.4944 44.1669 42.7197 46.0584C45.0563 47.9499 48.3387 48.8957 52.5668 48.8957C56.7948 48.8957 60.0215 47.9499 62.2469 46.0584C64.5834 44.1669 65.7517 41.4965 65.7517 38.0473C65.7517 34.3755 64.5834 31.5939 62.2469 29.7024C60.0215 27.6996 56.7948 26.6982 52.5668 26.6982ZM52.5668 73.5967C47.5598 73.5967 43.7211 74.7093 41.0508 76.9346C38.3804 79.1599 37.0452 82.3866 37.0452 86.6147C37.0452 90.6203 38.3804 93.7357 41.0508 95.9611C43.7211 98.0751 47.5598 99.1321 52.5668 99.1321C57.685 99.1321 61.5793 98.0751 64.2496 95.9611C66.92 93.7357 68.2552 90.6203 68.2552 86.6147C68.2552 82.3866 66.92 79.1599 64.2496 76.9346C61.5793 74.7093 57.685 73.5967 52.5668 73.5967Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

