import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon3 = observer('NumberIcon3', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 107 128'

            width='107'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M72.3354 52.27C81.3368 53.9578 88.538 57.6709 93.9388 63.4093C99.4522 69.0352 102.209 76.2363 102.209 85.0126C102.209 92.6638 100.184 99.4712 96.1329 105.435C92.1948 111.286 86.5127 115.842 79.0865 119.105C71.7729 122.368 63.2215 124 53.4325 124C44.6561 124 35.936 122.65 27.2722 119.949C18.6083 117.136 11.3509 113.48 5.5 108.979L18.1582 85.0126C23.2215 89.1758 28.5098 92.3826 34.0232 94.6329C39.5366 96.7707 45.1062 97.8397 50.7321 97.8397C56.2454 97.8397 60.4648 96.7707 63.3903 94.6329C66.3158 92.4951 67.7785 89.3446 67.7785 85.1814C67.7785 81.1308 66.3158 78.0366 63.3903 75.8987C60.4648 73.6484 56.1892 72.5232 50.5633 72.5232H29.4662V54.1266L54.7827 29.8228H11.7447V4H96.4705V24.0844L66.4283 51.0886L72.3354 52.27Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

