import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    MatchStatsChevron,
    MatchStatsToggleWrapper,
    MatchStatsWrapper,
    GoalWidget,
    SpiderWidget,
    XWidget,
    Separator,
} from 'src/domains/sportsbook/webview/components/scoreboards/matchStats/MatchStatsIframe.style';
import { observer } from 'src/utils/mobx-react';
import { MatchStatsIframeState } from './MatchStatsIframe.state';

interface ReferencePropsType {
    isOpen: boolean;
    homeTeamName: string;
    awayTeamName: string;
    gameDate: string;
    isMobile: boolean;
    isPremierLeague: boolean;
}

export const MatchStatsIframe = observer(
    'MatchStatsIframe',
    ({ isOpen, homeTeamName, awayTeamName, gameDate, isMobile, isPremierLeague }: ReferencePropsType): JSX.Element => {
        const [state] = useState(
            () => new MatchStatsIframeState(isOpen, homeTeamName, awayTeamName, gameDate, isMobile, isPremierLeague)
        );

        return (
            <>
                <MatchStatsToggleWrapper onClick={state.toggle}>
                    <MatchStatsChevron position={state.isOpen ? 'down' : 'right'} />
                    <I18n
                        langKey='scoreboard.match-stats.title'
                        defaultText='Match Stats'
                    />
                </MatchStatsToggleWrapper>

                {state.isOpen ? null : <Separator />}

                <MatchStatsWrapper isOpen={state.isOpen}>
                    <GoalWidget
                        ref={state.setGoalWidgetRef}
                        id='goal-widget'
                        hidden={isMobile || isPremierLeague === false}
                    />
                    <SpiderWidget
                        ref={state.setSpiderWidgetRef}
                        id='spider-widget'
                        hidden={isMobile}
                    />
                    <XWidget
                        ref={state.setXWidgetRef}
                        id='x-widget'
                    />
                </MatchStatsWrapper>
            </>
        );
    }
);
