import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { MarketSeparator } from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';
import { withConfig } from 'src/withConfig';


interface WidgetType {
    hidden: boolean;
}

interface MatchStatsWrapperType {
    isOpen: boolean;
}

export const MatchStatsToggleWrapper = withConfig(theme => styled('header', { label: 'MatchStatsToggleWrapper' })`
    align-items: center;
    background: ${theme.star.headings.market.bgColor};
    color: ${theme.star.headings.market.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 28px;
    line-height: 1.3334;
    padding: 8px;
    text-transform: capitalize;
`);

/* Wrapper for Match Stats Widget */
export const MatchStatsWrapper = withConfig(theme => styled('div', { label: 'MatchStatsWrapper' })<MatchStatsWrapperType>`
    height: ${({ isOpen }): string => (isOpen ? '100%' : '0')};
    margin: auto;
    overflow: hidden;
    padding: ${({ isOpen }): string => (isOpen ? '16px 16px 6px' : '0 16px')};
    position: relative;
    text-align: center;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.base};
    background-color: ${theme.star.matchStatsWidget.bgColor};
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;

    .pm-container {
        background-color: transparent !important;
    }

    .pm-x-metric-row,
    .pm-row {
        border-bottom: 1px solid ${theme.star.matchStatsWidget.txtColor};
        padding-bottom: 8px;
    }

    .pm-progress-inner-left,
    .pm-progress-inner-right {
        height: 12px !important; 
    }

    .pm-divider {
        display: none;
    }

    .pm-sum-text {
        font-weight: 600;
    }

    .pm-spider-tooltip-btn,
    .pm-xg-value,
    .pm-progress-inner-left,
    .pm-progress-inner-right {
        background: ${theme.star.matchStatsWidget.barColor} !important;
    }

    .pm-xg-value {
        color: ${theme.star.matchStatsWidget.barTxtColor} !important;
    }

    .pm-metric.pm-text,
    .pm-headline,
    .pm-xg-scorer,
    .pm-away-metric-text,
    .pm-home-metric-text,
    .pm-logo-text,
    .pm-spider-tooltip-label,
    .pm-data-powered-text,
    .pm-sum-text,
    .pm-away-name,
    .pm-home-name {
        color: ${theme.star.matchStatsWidget.txtColor} !important;
    }

    .pm-away,
    .pm-home {
        gap: 3px;
    }

    .pm-home {
        grid-template-columns: 30px auto !important;
    }

    .pm-away {
        grid-template-columns: auto 30px !important;
    }

    .pm-x-default-value,
    .pm-spider-tooltip {
        background-color: ${theme.star.matchStatsWidget.bgColor} !important;
    }

    .pm-spider-tooltip-btn {
        color: ${theme.star.matchStatsWidget.barTxtColor} !important;
    }

    .pm-x-home-win,
    .pm-spider-tooltip-value-home {
        background-color: ${theme.star.matchStatsWidget.homeColor} !important;
        color: black !important;
    }

    .pm-x-away-win,
    .pm-spider-tooltip-value-away {
        background-color: ${theme.star.matchStatsWidget.barColor} !important;
        color: ${theme.star.matchStatsWidget.barTxtColor} !important;
    }

    .pm-spider-container {
        --home-color: ${theme.star.matchStatsWidget.homeColor} !important;
        --away-color: ${theme.star.matchStatsWidget.barColor} !important;
    }

    * > tspan {
        font-weight: 400 !important;
        font-size: 12px;
    }

    * > polygon[fill='#ED0000'][stroke-width='2'] {
        fill: ${theme.star.matchStatsWidget.homeColor} !important;
        stroke: ${theme.star.matchStatsWidget.homeColor} !important;
    }

    * > polygon[fill='#256FEA'][stroke-width='2'] {
        fill: ${theme.star.matchStatsWidget.barColor} !important;
        stroke: ${theme.star.matchStatsWidget.barColor} !important;
    }

    .pm-x-row-team-name,
    .pm-away-name,
    .pm-home-name {
        font-weight: 600;
    }

    .pm-xg-scorer {
        font-size: 12px;
    }

    .pm-progress-inner-left,
    .pm-xg-home-team > .pm-xg-value {
        background-color: ${theme.star.matchStatsWidget.homeColor} !important;
        color: black !important;
    }
`);

export const MatchStatsChevron = styled(ChevronIcon, { label: 'MatchStatsChevron' })`
    fill: currentcolor;
    width: 12px;
    margin-right: 8px;
`;

export const GoalWidget = styled('div', { label: 'GoalWidget' })<WidgetType>`
    flex-grow: 1;
    display: ${({ hidden }): string => (hidden ? 'none' : 'block')};
`; 

export const SpiderWidget = styled('div', { label: 'SpiderWidget' })<WidgetType>`
    flex-grow: 1;
    display: ${({ hidden }): string => (hidden ? 'none' : 'block')};
`; 

export const XWidget = styled('div', { label: 'XWidget' })`
    flex-grow: 1;
`; 

export const Separator = styled(MarketSeparator, { label: 'Separator' })``;

