import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { WithdrawPaysafeProcedure } from './WithdrawPaysafeProcedure';
import { WithdrawPaysafeFormState } from './WithdrawPaysafeFormState';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    MessageIframeYaspaDataType,
    YaspaIframe,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaIframe';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

export type WithdrawPaysafeStepsType =
    | {
          type: 'set-card';
      }
    | {
          readonly type: 'iframe-view-yaspa';
          url: string;
      }
    | {
          type: 'failure-view';
          readonly failureType: WithdrawFailuresType;
      }
    | {
          type: 'withdraw-pending';
      };

export type WithdrawFailuresType = 'serverIssue' | 'noPaymentMethods';

const defaultWithdrawStep: WithdrawPaysafeStepsType = {
    type: 'set-card',
};

export class WithdrawPaysafeSteps {
    @observable.ref public step: WithdrawPaysafeStepsType;

    public constructor(public initialStep?: WithdrawPaysafeStepsType) {
        makeObservable(this);
        this.step = initialStep ?? defaultWithdrawStep;
    }

    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToIframeYaspa = ({ url }: { url: string }): void => {
        this.step = {
            type: 'iframe-view-yaspa',
            url,
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };

    @action public redirectFromYaspaIframe = (data?: MessageIframeYaspaDataType): void => {
        if (data?.eventType === 'onCloseHiddenIframeFail') {
            this.step = {
                type: 'failure-view',
                failureType: 'serverIssue',
            };
        } else if (data?.eventType === 'onCloseHiddenIframeSuccess') {
            this.step = {
                type: 'withdraw-pending',
            };
        }
    };
}

export const WithdrawPaysafeJourney = observer('WithdrawPaysafeJourney', () => {
    const { appPlayersState } = useAppStateContext();
    const { usersState, withdrawalsListState } = appPlayersState;
    const common = useCommon();
    const googleTagManager = GoogleTagManagerState.get(common);
    const languagesState = LanguagesState.get(common);
    const basicDataModel = BasicDataModel.get(common);
    const {
        config: { minWithdrawAmount, usePaymentFormForUniverse, useOpenBanking },
    } = ConfigComponents.get(common);

    const [paymentMethodDropdownState] = useState(
        () =>
            new PaymentMethodDropdownState(common.trpcClient, usePaymentFormForUniverse, useOpenBanking, basicDataModel)
    );

    const [state] = React.useState(
        () =>
            new WithdrawPaysafeFormState(
                common.trpcClient,
                usersState,
                languagesState,
                withdrawalsListState,
                googleTagManager,
                paymentMethodDropdownState,
                new Amount(minWithdrawAmount)
            )
    );

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <WithdrawProcedureWrapper data-test='withdraw-procedure-wrapper'>
                        <WithdrawPaysafeProcedure
                            state={state}
                            paymentMethodDropdownState={paymentMethodDropdownState}
                        />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'iframe-view-yaspa':
            return (
                <YaspaIframe
                    src={state.currentStep.url}
                    onMessage={state.stepsState.redirectFromYaspaIframe}
                />
            );
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
