import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { NumberIcon1 } from './numeric-slider-icons/NumberIcon1';
import { NumberIcon2 } from './numeric-slider-icons/NumberIcon2';
import { NumberIcon3 } from './numeric-slider-icons/NumberIcon3';
import { NumberIcon4 } from './numeric-slider-icons/NumberIcon4';
import { NumberIcon5 } from './numeric-slider-icons/NumberIcon5';
import { NumberIcon6 } from './numeric-slider-icons/NumberIcon6';
import { NumberIcon7 } from './numeric-slider-icons/NumberIcon7';
import { NumberIcon8 } from './numeric-slider-icons/NumberIcon8';
import { NumberIcon9 } from './numeric-slider-icons/NumberIcon9';
import { NumberIcon10 } from './numeric-slider-icons/NumberIcon10';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

const slideNumberIconStyles = (theme: EmotionTheme): SerializedStyles => {
    return css`
        bottom: 0;
        left: 0;
        position: absolute;
        stroke: ${theme.star.casino.casinoSlider.bgColor};
        transform-origin: left bottom;
        transform: scale(0.5);
        @media ${theme.star.mediaQuery.tablet} {
            transform: scale(1);
        }
    `;
};

export const SlideNumberIcon1 = withConfig(theme => styled(NumberIcon1, { label: 'SlideNumberIcon1' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon2 = withConfig(theme => styled(NumberIcon2, { label: 'SlideNumberIcon2' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon3 = withConfig(theme => styled(NumberIcon3, { label: 'SlideNumberIcon3' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon4 = withConfig(theme => styled(NumberIcon4, { label: 'SlideNumberIcon4' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon5 = withConfig(theme => styled(NumberIcon5, { label: 'SlideNumberIcon5' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon6 = withConfig(theme => styled(NumberIcon6, { label: 'SlideNumberIcon6' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon7 = withConfig(theme => styled(NumberIcon7, { label: 'SlideNumberIcon7' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon8 = withConfig(theme => styled(NumberIcon8, { label: 'SlideNumberIcon8' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon9 = withConfig(theme => styled(NumberIcon9, { label: 'SlideNumberIcon9' })`
    ${slideNumberIconStyles(theme)};
`);

export const SlideNumberIcon10 = withConfig(theme => styled(NumberIcon10, { label: 'SlideNumberIcon10' })`
    ${slideNumberIconStyles(theme)};
`);

export const GameNumericWrapper = withConfig(theme => styled('div', { label: 'GameNumericWrapper' })`
    overflow: hidden;
    position: relative;
    width: 158px;
    height: 175px;
    &:last-of-type {
        margin-right: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        width: 315px;
        height: 350px;
    }
`);

export const GameNumericImgWrapper = withConfig(theme => styled('div', { label: 'GameNumericImgWrapper' })`
    position: relative;
    width: 130px;
    height: 175px;
    margin-left: auto;
    &:hover > div {
        opacity: .8;
        visibility: visible;
    }
    @media ${theme.star.mediaQuery.tablet} {
        width: 260px;
        height: 350px;
    }
`);
