import { action, computed, observable, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { UsersState } from 'src/domains/players/state/UsersState';
import {
    WithdrawSteps,
    WithdrawStepsType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawJourney';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import {
    validateAmountRequire,
    validateAmountPattern,
    validateMinAmount,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';
import { DepositYaspaPageType } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaSegment/YaspaSegment.state';

export class WithdrawFormState {
    @observable public withdrawSuccessMessage: string | null = null;
    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public stepsState: WithdrawSteps;

    public amountState: FormInputState<string, Amount>;

    public constructor(
        private readonly usersState: UsersState,
        private readonly language: LanguagesState,
        private readonly withdrawalsListState: WithdrawalsListState,
        private readonly googleTagManager: GoogleTagManagerState,
        private readonly paymentMethodDropdownState: PaymentMethodDropdownState,
        private readonly minWithdraw: Amount,
        private readonly amountPrecision: AmountPrecision
    ) {
        makeObservable(this);
        this.stepsState = new WithdrawSteps();
        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(validateMinAmount(this.minWithdraw, () => usersState.moneySymbol, 'ERROR_MIN_WITHDRAW'));
    }

    @computed public get showBalance(): string | null {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
        return null;
    }

    public handleAmountInputChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.withdrawErrorMessage = null;
        const value = event.currentTarget.value;
        const formatValue = amountViewToValue(value);
        this.amountState.setValue(formatValue);
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.amountState.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.amountState.setValue('');
        }
        return this.amountState.setValue(amount.toFixed(2));
    };

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.amountState.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawSuccessMessage = null;
        this.withdrawErrorMessage = null;

        if (
            this.paymentMethodDropdownState.selectedPaymentMethod === undefined ||
            this.amountState.result.value.type === 'error'
        ) {
            this.isWithdrawFormSubmitting = false;
            return;
        }

        try {
            const response = await this.usersState.withdraw({
                requestBody: {
                    status: 'requested',
                    amount: this.amountPrecision.valueOldFormat(this.amountState.result.value.data),
                    payment_method: this.paymentMethodDropdownState.selectedPaymentMethod,
                },
            });

            if (response.status === '422') {
                if (response.errors.length === 0) {
                    throw new Error(this.language.getTranslation('errors.unknown', 'Unknown error'));
                }

                if (response.errors[0]?.code === 'withdrawable-balance-at-least') {
                    this.withdrawErrorMessage = this.language.getTranslation(
                        'errors.withdrawable-balance-at-least',
                        'You have exceeded your withdrawable balance.'
                    );
                    this.isWithdrawFormSubmitting = false;
                    return;
                }

                this.withdrawErrorMessage = response.errors[0]?.debugDetails ?? '';
                this.isWithdrawFormSubmitting = false;
                return;
            }

            // eslint-disable-next-line no-restricted-globals
            this.googleTagManager.addWithdrawnTag(Number(this.amountState.value));
            this.withdrawSuccessMessage = this.language.getTranslation(
                'account.withdraw.success-message',
                'Withdraw request successfully.'
            );
            await this.withdrawalsListState.refresh();
            this.amountState.reset();
            this.stepsState.redirectToPending();
            this.isWithdrawFormSubmitting = false;
        } catch (e) {
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    @computed public get currentStep(): WithdrawStepsType {
        const methods = this.paymentMethodDropdownState.savedPaymentMethods;
        const status = this.paymentMethodDropdownState.savedPaymentMethodResource.get();
        const hasOpenBanking = this.paymentMethodDropdownState.savedOpenBankingMethods.length > 0;
        const isCardNoSaved = (methods === null || methods.length === 0) && status.type === 'ready';

        if (isCardNoSaved && hasOpenBanking === false) {
            return {
                type: 'failure-view',
                failureType: 'noPaymentMethods',
            };
        }

        return this.stepsState.step;
    }

    @computed public get isBtnDisabled(): boolean {
        const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;
        if (withdrawableBalance === undefined) {
            return true;
        }

        if (this.amountState.result.value.type === 'error') {
            return true;
        }
        const withdrawableBalanceAmount = new Amount(withdrawableBalance);

        if (this.amountState.result.value.data.isGreaterThan(withdrawableBalanceAmount)) {
            return true;
        }

        if (this.paymentMethodDropdownState.selectedPaymentMethod === undefined) {
            return true;
        }

        return false;
    }

    @computed public get depositYaspaPage(): DepositYaspaPageType {
        return {
            isSignup: false,
            getAmountInput: () => this.amountState,
            handleRedirectToYaspaFrame: (link) => this.stepsState.redirectToIframeYaspa(link),
        };
    }
}
