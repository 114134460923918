import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon2 = observer('NumberIcon2', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='129'

            viewBox='0 0 106 129'

            width='106'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M101.331 97.1197V124.5H9.72535L9.55634 102.021L51.8099 58.4155C54.8521 55.3732 57.2746 52.331 59.0775 49.2887C60.993 46.1338 61.9507 43.4296 61.9507 41.1761C61.9507 38.4718 60.993 36.4437 59.0775 35.0915C57.2746 33.6268 54.6831 32.8944 51.3028 32.8944C46.6831 32.8944 41.5 34.2465 35.7535 36.9507C30.1197 39.5423 24.4296 43.2042 18.6831 47.9366L5.5 22.5845C23.0775 10.5282 40.0916 4.5 56.5423 4.5C64.6549 4.5 71.8662 5.85211 78.1761 8.55634C84.5986 11.1479 89.6127 14.8099 93.2183 19.5422C96.9366 24.2746 98.7958 29.7394 98.7958 35.9366C98.7958 42.1338 96.7676 48.5 92.7113 55.0352C88.7676 61.4577 83.0775 68.3873 75.6409 75.8239L54.3451 97.1197H101.331Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});



