import React, { ReactElement, ReactNode, useState, useRef, useEffect } from 'react';
import { makeAutoObservable, autorun } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

interface RefObserverType {
    ref: HTMLDivElement | null;
    observer: IntersectionObserver | null;
}

class InfiniteScrollWrapperState {
    public displayedAmount: number;
    private refObserver: RefObserverType = { ref: null, observer: null };
    private currentView: string;

    public constructor(
        private initAmount: number,
        private increaseAmount: number,
        private getView: () => string
    ) {
        makeAutoObservable(this);
        this.displayedAmount = this.initAmount;
        this.currentView = this.getView();

        autorun(() => {
            const newView = this.getView();
            if (newView !== this.currentView) {
                this.displayedAmount = this.initAmount;
                this.currentView = newView;
            }
        });
    }

    public getDisplayedArray = (array: Array<ReactNode>): Array<ReactNode> => {
        return array.slice(0, this.displayedAmount);
    };

    private loadMoreContent = (): void => {
        this.displayedAmount += this.increaseAmount;
    };

    public setRef = (ref: HTMLDivElement | null, array: Array<ReactNode>): void => {
        if (this.refObserver.ref !== null && this.refObserver.observer !== null) {
            this.refObserver.observer.unobserve(this.refObserver.ref);
        }

        if (ref === null) {
            this.refObserver = { ref: null, observer: null };
        } else {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (this.displayedAmount < array.length)
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                this.loadMoreContent();
                            }
                        });
                },
                { rootMargin: '50px' }
            );

            observer.observe(ref);
            this.refObserver = { ref, observer };
        }
    };

    public cleanupObserver = (): void => {
        if (this.refObserver.observer !== null && this.refObserver.ref !== null) {
            this.refObserver.observer.unobserve(this.refObserver.ref);
            this.refObserver = { ref: null, observer: null };
        }
    };
}

interface InfiniteScrollWrapperType {
    initAmount: number;
    increaseAmount: number;
    children: Array<ReactNode>;
}

export const InfiniteScrollWrapper = observer(
    'InfiniteScrollWrapper',
    (props: InfiniteScrollWrapperType): ReactElement => {
        const { children: array, initAmount, increaseAmount } = props;
        const sentinelRef = useRef<HTMLDivElement | null>(null);
        const common = useCommon();
        const router = StarRouter.get(common);

        const [state] = useState(
            () => new InfiniteScrollWrapperState(initAmount, increaseAmount, () => JSON.stringify(router.currentView))
        );

        const { getDisplayedArray, setRef, cleanupObserver } = state;

        useEffect(() => {
            return () => cleanupObserver();
        }, [cleanupObserver]);

        return (
            <>
                {getDisplayedArray(array)}
                <div
                    ref={(ref): void => {
                        setRef(ref, array);
                        sentinelRef.current = ref;
                    }}
                />
            </>
        );
    }
);
