import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon7 = observer('NumberIcon7', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 107 128'

            width='107'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M4.5 4H102.075V27.9658L58.7653 124H21.276L62.7026 31.7318H35.9979V48.5078H4.5V4Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

