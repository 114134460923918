import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { MatchStatsIframe } from 'src/domains/sportsbook/webview/components/scoreboards/matchStats/MatchStatsIframe';
import { DateTime } from 'src_common/utils/time/time';

interface PropsType {
    isOpen: boolean;
    homeTeamName: string | null;
    awayTeamName: string | null;
    leagueName: string | undefined;
    gameDate: string;
}

export const MatchStats = observer(
    'MatchStats',
    ({ gameDate, isOpen, homeTeamName, awayTeamName, leagueName }: PropsType): JSX.Element | null => {
        const {
            config: { scoreboard },
            appCasinoState,
        } = useAppStateContext();

        const validLeagues = [
            'England Premier League',
            'France Ligue 1',
            'Germany Bundesliga 1',
            'Spain Primera Division',
            'FA Cup',
            'Italy Serie A',
            'UEFA Champions League',
            'Europa Conference League',
            'UEFA Europa League',
        ];
        const isMobile = appCasinoState.breakpointsState.tablet.isBiggerOrEq === false;
        const isPremierLeague = leagueName === 'England Premier League';
        const startDate = DateTime.from(gameDate);

        if (
            scoreboard.showMatchStats === false ||
            homeTeamName === null ||
            awayTeamName === null ||
            leagueName === undefined ||
            !validLeagues.includes(leagueName) ||
            startDate === null ||
            startDate.diffInDays(DateTime.current()) >= 7
        ) {
            return null;
        }

        return (
            <MatchStatsIframe
                key={homeTeamName}
                gameDate={gameDate}
                isOpen={isOpen}
                homeTeamName={homeTeamName}
                awayTeamName={awayTeamName}
                isMobile={isMobile}
                isPremierLeague={isPremierLeague}
            />
        );
    }
);
