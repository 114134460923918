import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { LiveTrackerIframe } from 'src/domains/sportsbook/webview/components/scoreboards/liveTracker/LiveTrackerIframe';
import { getLiveTrackerContainerConfig } from 'src/domains/sportsbook/webview/components/scoreboards/liveTracker/ContainerConfig';

interface PropsType {
    sport: string;
    eventId: number;
    isOpen: boolean;
}

export const LiveTracker = observer('LiveTracker', ({ sport, eventId, isOpen }: PropsType): JSX.Element | null => {
    const {
        config: { scoreboard, liveTracker },
    } = useAppStateContext();

    if (scoreboard.liveTracker === null || liveTracker === null) {
        return null;
    }

    for (const item of scoreboard.liveTracker) {
        const containerConfigItem = getLiveTrackerContainerConfig(liveTracker.scriptSrcUrl)[item];

        if (item === sport && containerConfigItem !== undefined) {
            return (
                <LiveTrackerIframe
                    eventId={eventId}
                    providerName={liveTracker.providerName}
                    containerConfig={containerConfigItem}
                    isOpen={isOpen}
                />
            );
        }
    }

    return null;
});
