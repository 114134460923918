import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon4 = observer('NumberIcon4', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 109 128'

            width='109'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M104.3 73.5007V99.5207H89.7496V124H57.2247V99.5207H4.5V76.0685L36.169 4H69.7211L40.2775 73.5007H57.2247V49.1926H89.7496V73.5007H104.3Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

