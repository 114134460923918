import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon9 = observer('NumberIcon9', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 103 128'

            width='103'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M19.0202 90.7872C25.3623 95.7942 33.0953 98.2976 42.2191 98.2976C50.4527 98.2976 56.628 96.0167 60.7448 91.4548C64.9729 86.8929 67.0313 80.3282 66.92 71.7608C60.9117 79.2156 52.3999 82.943 41.3846 82.943C34.041 82.943 27.5876 81.3853 22.0243 78.2698C16.4611 75.0431 12.1217 70.5369 9.00626 64.751C6.00209 58.854 4.5 52.1224 4.5 44.5563C4.5 36.3227 6.28025 29.146 9.84075 23.0264C13.5125 16.9068 18.6307 12.2337 25.1954 9.00696C31.8713 5.66899 39.5487 4 48.2274 4C58.5751 4 67.532 6.39221 75.0981 11.1766C82.6641 15.8498 88.4499 22.5814 92.4555 31.3714C96.5723 40.0501 98.6307 50.3421 98.6307 62.2476C98.6307 74.5981 96.4054 85.4465 91.9548 94.7928C87.5042 104.139 81.162 111.371 72.9284 116.49C64.6947 121.497 55.1259 124 44.2218 124C37.5459 124 30.7587 123.11 23.8602 121.33C17.073 119.549 11.2316 117.102 6.33588 113.986L19.0202 90.7872ZM49.2288 30.5369C44.7782 30.5369 41.3289 31.7608 38.8811 34.2086C36.5445 36.6565 35.3762 40.1613 35.3762 44.7232C35.3762 49.1739 36.5445 52.6231 38.8811 55.0709C41.2177 57.4075 44.6113 58.5758 49.0619 58.5758C53.735 58.5758 57.2955 57.2962 59.7434 54.7371C62.3025 52.178 63.5821 48.7288 63.5821 44.3894C63.5821 40.1613 62.3581 36.8234 59.9103 34.3755C57.5737 31.8164 54.0132 30.5369 49.2288 30.5369Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

