import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PaymentMethodDropdown } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/paymentMethodDropdown/PaymentMethodDropdown';
import { WithdrawProcedureBtnWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure.style';
import { observer } from 'src/utils/mobx-react';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { WithdrawPaysafeFormState } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawPaysafe/WithdrawPaysafeFormState';

interface WithdrawCardSegmentPropType {
    state: WithdrawPaysafeFormState;
    paymentMethodDropdownState: PaymentMethodDropdownState;
}

export const WithdrawCardSegment = observer(
    'WithdrawCardSegment',
    ({ state, paymentMethodDropdownState }: WithdrawCardSegmentPropType) => {
        return (
            <>
                <PaymentMethodDropdown
                    key='payment-method-dropdown'
                    label={
                        <I18n
                            langKey='account.top-up.payment-method-dropdown.select.label'
                            defaultText='Select Card'
                        />
                    }
                    options={paymentMethodDropdownState.savedPaymentMethods}
                    value={paymentMethodDropdownState.paymentMethodsValue}
                    onChangeCB={paymentMethodDropdownState.handlePaymentSelectChange}
                    refreshPaymentMethods={paymentMethodDropdownState.refreshPaymentMethods}
                />
                {state.withdrawErrorMessage === null ? null : (
                    <Messages
                        type='error'
                        message={state.withdrawErrorMessage}
                    />
                )}
                <WithdrawProcedureBtnWrapper
                    size='large'
                    onClick={state.submitWithdrawForm}
                    disabled={state.isBtnDisabled || state.isWithdrawFormSubmitting}
                    dataTest='withdraw-procedure-btn'
                >
                    <I18n
                        langKey='account.withdraw.submit.label'
                        defaultText='Withdraw'
                    />
                </WithdrawProcedureBtnWrapper>
            </>
        );
    }
);
