import { action, computed, makeObservable } from 'mobx';
import { ALL_GAMES, FAVOURITE_GAMES, HOME, SupplierType, TabIdType } from 'src/domains/casino/utils/types';
import { convertTabIdTypeToUrl, convertUrlToTabIdType, sortGamesAlphabetically } from 'src/domains/casino/utils/utils';
import { CasinoGameModelExt } from './store/game-store/CasinoGameModelExt';
import { CasinoCollectionModelExt } from './store/game-store/CasinoCollectionModelExt';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { RedirectState } from './Redirect.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { CollectionType } from 'src_common/playwright/types/casino/categoriesAndGamesResponse';

const filterAndSortSuppliersList = (suppliersList: Array<SupplierType>): Array<SupplierType> => {
    const suppliersWithoutDuplicates = suppliersList.filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    const sortedSuppliersList = suppliersWithoutDuplicates.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    return sortedSuppliersList;
};

export class CasinoNavigationState {
    public constructor(
        private readonly redirectState: RedirectState,
        private readonly languagesState: LanguagesState,
        private readonly collection: CasinoCollectionModelExt,
        private readonly collectionType: CollectionType,
        private readonly tabId: string | null,
        private readonly navigationOffset: () => number | null
    ) {
        makeObservable(this);
    }

    @computed public get activeTabId(): TabIdType {
        return convertUrlToTabIdType(this.redirectState.getIsUserAutorized(), this.tabId);
    }

    @computed public get activeTabIdForUrl(): string {
        return convertTabIdTypeToUrl(this.activeTabId);
    }

    @action public onTabChange = (tabId: string): void => {
        const navigationOffset = this.navigationOffset() ?? 0;
        this.redirectState.redirectToCollectionTab(this.collectionType, tabId, navigationOffset);
    };

    @computed public get activeSupplierId(): string | null {
        if (this.activeTabId.type === 'supplier_games') {
            return this.activeTabIdForUrl;
        }
        return null;
    }

    @computed public get isSearchActive(): boolean {
        return this.collection.isSearchActive;
    }

    @computed public get isHomeActive(): boolean {
        return this.activeTabId.type === 'home';
    }

    @computed public get gamesListToDisplay(): Array<CasinoGameModelExt> | 'loading' {
        if (this.collection.isLoading) {
            return 'loading';
        }

        const activeTab = this.activeTabId;

        if (this.collection.isSearchActive) {
            return this.collection.filterByVisibleInAllGames().filterBySearch().games;
        }

        if (activeTab.type === 'supplier_games') {
            return this.collection
                .filterByVisibleInAllGames()
                .filterBySearch()
                .games.filter((game) => {
                    return game.studioId === activeTab.id;
                });
        }

        if (activeTab.type === 'favourite_games') {
            return this.collection.filterByFavouriteGames().games;
        }

        if (activeTab.type === 'all_games') {
            if (this.collectionType === 'virtuals') {
                return this.collection.filterByVisibleInAllGames().games;
            }
            return sortGamesAlphabetically(this.collection.filterByVisibleInAllGames().games);
        }

        if (activeTab.type === 'numeric') {
            return this.collection.filterByCategoryId(activeTab.id).games;
        }
        return [];
    }

    @computed public get gamesListToDisplayNonCategorized(): Array<CasinoGameModelExt> | 'loading' {
        // Virtuals don't have categories
        if (this.collection.isLoading) {
            return 'loading';
        }

        if (this.collection.isSearchActive) {
            return sortGamesAlphabetically(this.collection.filterByVisibleInAllGames().filterBySearch().games);
        }

        return this.collection.filterByVisibleInAllGames().games;
    }

    @computed public get filtersHome(): Array<FiltersType> {
        const activeTab = this.activeTabId;

        const homeCategory: FiltersType = {
            key: HOME,
            label: this.languagesState.getTranslation('casino.tabs.all-games.label.home', 'Home'),
            dataTest: `casino-filter-${HOME}`,
            onClick: (): void => {
                this.onTabChange(HOME);
            },
        };

        const fixedCategories: Array<FiltersType> = this.collection.fixedCategoriesVisibleInNavigation.map(
            (category) => ({
                key: category.id.toString(),
                label: category.name,
                dataTest: `casino-filter-${category.id.toString()}`,
                onClick: (): void => {
                    this.onTabChange(category.id.toString());
                },
            })
        );

        const allGamesCategory: FiltersType = {
            key: ALL_GAMES,
            label: this.languagesState.getTranslation('casino.tabs.all-games.label.all-games', 'All Games'),
            dataTest: `casino-filter-${ALL_GAMES}`,
            onClick: (): void => {
                this.onTabChange(ALL_GAMES);
            },
        };

        const favouriteGamesCategory: FiltersType = {
            key: FAVOURITE_GAMES,
            label: this.languagesState.getTranslation('casino.tabs.favourite-games.label', 'Favourite Games'),
            dataTest: `casino-filter-${FAVOURITE_GAMES}`,
            onClick: (): void => {
                this.onTabChange(FAVOURITE_GAMES);
            },
        };

        const customCategories: Array<FiltersType> = this.collection.customCategoriesVisibleInNavigation.map(
            (category) => ({
                key: category.id.toString(),
                label: category.name,
                dataTest: `casino-filter-${category.id.toString()}`,
                onClick: (): void => {
                    this.onTabChange(category.id.toString());
                },
            })
        );

        const ghostCategories: Array<FiltersType> = [];
        if (activeTab.type === 'numeric') {
            const ghostCategoryToLoad = this.collection
                .getCategories({ hasGames: true, isVisibleInNavigation: false })
                .filter((category) => {
                    return category.id === activeTab.id;
                });

            ghostCategories.push(
                ...ghostCategoryToLoad.map((category) => ({
                    key: category.id.toString(),
                    label: category.name,
                    dataTest: `casino-filter-${category.id.toString()}`,
                    onClick: (): void => {
                        this.onTabChange(category.id.toString());
                    },
                }))
            );
        }

        if (this.collection.getUserFavouriteGames.length > 0) {
            return [
                homeCategory,
                ...fixedCategories,
                allGamesCategory,
                favouriteGamesCategory,
                ...customCategories,
                ...ghostCategories,
            ];
        }

        return [homeCategory, ...fixedCategories, allGamesCategory, ...customCategories, ...ghostCategories];
    }

    @computed public get gamesSuppliersToDisplay(): Array<SupplierType> {
        const suppliers: Array<SupplierType> = [];

        this.collection.filterByVisibleInAllGames().games.map((game) => {
            if (game.studioId !== '' && game.studioName !== '') {
                suppliers.push({
                    id: `supplier_${game.studioId}`,
                    name: game.studioName,
                });
            }
        });

        return filterAndSortSuppliersList(suppliers);
    }

    @computed public get shouldRenderSuppliersFilter(): boolean {
        return this.gamesSuppliersToDisplay.length > 1;
    }
}
