import { action, computed, makeObservable, observable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { DateTime } from 'src_common/utils/time/time';
import { getTransactions } from 'src_server/trpc/types/payments';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { Amount } from 'src_common/common/amount/Amount';
import { pickCriteria } from './pickCriteria';
export type TransactionType = 'deposit' | 'withdrawal' | 'adjustment';

export class MonthlyActivityPopupState {
    private readonly transactionsSummary: AutoMap<
        [number, TransactionType, string, string],
        Resource<getTransactions.TresponseType>
    >;
    @observable public isButtonDisabled: boolean = false;

    public constructor(private readonly common: Common) {
        makeObservable(this);

        this.transactionsSummary = new AutoMap(
            ([customerId, transactionType, startDate, endDate]) =>
                new Resource(async () => {
                    return await this.common.trpcClient.client.payments.getTransactions.mutate({
                        criteria: pickCriteria(customerId, transactionType, startDate, endDate),
                        aggregations: {
                            limit: {
                                type: 'sum',
                                field: 'amount',
                            },
                        },
                        sort: [],
                    });
                })
        );
    }

    @computed public get getPeriod(): { startDate: string | undefined; endDate: string | undefined } {
        const newDate = new Date();
        const period = DateTime.from(newDate)?.subtractMonths(1);

        const startDate = period?.startOfMonths().toISOString();
        const endDate = period?.endOfMonths().toISOString();

        return { startDate, endDate };
    }

    @computed public get getFormatedPeriod(): string {
        const { startDate, endDate } = this.getPeriod;

        if (startDate === undefined || endDate === undefined) {
            return '';
        }

        const start = DateTime.from(startDate)?.format('Do MMM') ?? '';
        const end = DateTime.from(endDate)?.format('Do MMM') ?? '';
        const year = DateTime.from(endDate)?.format('YY') ?? '';

        return `${start} - ${end} ${year}`;
    }

    private getTransactionsByType = (transactionType: TransactionType): Amount | null => {
        const basicData = BasicDataModel.get(this.common).basicDataReady;
        if (basicData?.id === undefined) {
            return null;
        }

        const { startDate, endDate } = this.getPeriod;
        if (startDate === undefined || endDate === undefined) {
            return null;
        }

        const transactionsResponse = this.transactionsSummary
            .get([basicData.id, transactionType, startDate, endDate])
            .getReady();

        if (transactionsResponse?.responseStatus === 'error') {
            console.error('transactions response error', transactionsResponse.data);
            return null;
        }

        const limit = transactionsResponse?.response.aggregations.limit;
        if (limit === undefined) {
            console.error('transactions limit undefined');
            return null;
        }

        return ConfigComponents.get(this.common).precision.newFromOld(limit);
    };

    @computed public get getDeposits(): string | null {
        const deposits = this.getTransactionsByType('deposit');
        if (deposits === null || deposits.isEqualWithZero()) {
            return null;
        }

        return deposits.format(BasicDataModel.get(this.common).currency);
    }

    @computed public get getWithdrawals(): string | null {
        const withdrawals = this.getTransactionsByType('withdrawal');
        const adjustments = this.getTransactionsByType('adjustment');

        if ((withdrawals?.isEqualWithZero() ?? true) === true && (adjustments?.isEqualWithZero() ?? true) === true) {
            return null;
        }

        if (withdrawals !== null && adjustments !== null) {
            return withdrawals.add(adjustments).format(BasicDataModel.get(this.common).currency);
        }

        if (withdrawals !== null) {
            return withdrawals.format(BasicDataModel.get(this.common).currency);
        }

        if (adjustments !== null) {
            return adjustments.format(BasicDataModel.get(this.common).currency);
        }

        return null;
    }

    @action public handleSubmit = async (): Promise<void> => {
        this.isButtonDisabled = true;
        try {
            const response = await this.common.trpcClient.client.accounts.changeMonthlyActivityReportModal.mutate({
                monthlyActivityReportLastConfirmedAt: new Date().toISOString(),
            });

            if (response.responseStatus === 'error') {
                console.error('changeMonthlyActivityReportModal request error', response.data);
                return;
            }

            await BasicDataModel.get(this.common).basicData.refreshAndWait();
            return;
        } catch (e) {
            console.error('changeMonthlyActivityReportModal request error', e);
        } finally {
            this.isButtonDisabled = false;
        }
    };
}
