import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    SelectWrapper,
    SelectInputContainer,
    SelectInputLabel,
    StyledTrashIcon,
    ExpiresContainer,
} from './PaymentMethodDropdown.style';
import { ChevronIconWrapper } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';
import { components, OptionProps } from 'react-select';
import { useAppStateContext } from 'src/appState/AppState';
import { getSavedCards } from 'src_server/trpc/types/payments';
import * as z from 'zod';
import { DateTime } from 'src_common/utils/time/time';
import { DeleteCardPopup } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/DeleteCardPupup/DeleteCardPopup';
import { DeleteCardPopupState } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/DeleteCardPupup/DeleteCardPopup.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

const optionType = z.object({
    label: z.string(),
    value: z.string(),
    expires: z.string().optional().nullable(),
    isDisabled: z.boolean(),
});

export type OptionType = z.infer<typeof optionType>;

export class PaymentMethodDropdownStateInner {
    public constructor(private readonly onChangeCB: (value: string) => void) {}

    public onChange = (value: string): void => {
        this.onChangeCB(value);
    };
}

export interface DropDownPropsType {
    allowAddingMethod?: boolean;
    label: string | JSX.Element;
    options: getSavedCards.SavedCardInterfaceType[] | null;
    value: string;
    onChangeCB: (value: string) => void;
    refreshPaymentMethods: () => Promise<void>;
}

const PaymentMethodOption = observer(
    'PaymentMethodOption',
    ({
        optionProps,
        handleOpenRemoveCardPopup,
    }: {
        optionProps: OptionProps;
        handleOpenRemoveCardPopup: (id: string, label: string) => void;
    }) => {
        const parsedData = optionType.safeParse(optionProps.data);

        if (parsedData.success === false) {
            console.error('Payment Methods Dropdown - payment method option error');
            return null;
        }

        const { isDisabled, expires, label, value } = parsedData.data;

        return (
            <components.Option {...optionProps}>
                <div>{label}</div>
                {isDisabled ? (
                    <ExpiresContainer>
                        Expired: {expires}
                        <StyledTrashIcon
                            onClick={(): void => {
                                handleOpenRemoveCardPopup(value, label.replaceAll(' ', '').slice(-8));
                            }}
                        />
                    </ExpiresContainer>
                ) : null}
            </components.Option>
        );
    }
);

export const PaymentMethodDropdown = observer('PaymentMethodDropdown', (props: DropDownPropsType) => {
    const { allowAddingMethod = false, label, options, value, onChangeCB, refreshPaymentMethods } = props;
    const { common } = useAppStateContext();

    const languageState = LanguagesState.get(common);

    const [state] = useState(() => new PaymentMethodDropdownStateInner(onChangeCB));
    const [deleteCardPopupState] = useState(() => new DeleteCardPopupState(common, refreshPaymentMethods));

    const onChange = (selectedOption: unknown): void => {
        const parsedOptionType = optionType.safeParse(selectedOption);
        if (parsedOptionType.success) {
            state.onChange(parsedOptionType.data.value);
            return;
        }
        console.error('Payment Methods Dropdown - payment method onChange error');
        return;
    };

    const allOptions = (): OptionType[] => {
        if (options === null) {
            return [];
        }

        const optionsParsed = options.map((v) => {
            const cardNumber = v.number === undefined ? '0000' : v.number;
            return {
                value: v.id,
                label: `**** **** **** ${cardNumber.slice(-4)}`,
                expires: DateTime.from(v.expires ?? undefined)?.format('MM/YY') ?? null,
                isDisabled: DateTime.current().isAfter(DateTime.from(v.expires ?? undefined) ?? DateTime.current()),
            };
        });

        if (allowAddingMethod) {
            optionsParsed.unshift({
                label: languageState.getTranslation('account.payment-method-dropdown.new.label', 'New payment method'),
                value: 'none',
                expires: null,
                isDisabled: false,
            });
            return optionsParsed;
        }

        return optionsParsed;
    };

    const activeElement = (): OptionType | null => {
        const active = allOptions().find((option) => value === option.value) ?? null;
        return active;
    };

    const filteredOptions = (): OptionType[] => {
        return [...allOptions()].filter((option) => option.value !== activeElement()?.value);
    };

    return (
        <>
            <SelectInputContainer>
                <SelectInputLabel data-test='select-input-label'>{label}</SelectInputLabel>
                <SelectWrapper
                    value={activeElement()}
                    options={filteredOptions()}
                    className='custom-dropdown-select'
                    classNamePrefix='react-select'
                    onChange={onChange}
                    components={{
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        Option: (optionProps: OptionProps): JSX.Element => (
                            <PaymentMethodOption
                                optionProps={optionProps}
                                handleOpenRemoveCardPopup={deleteCardPopupState.handleOpenRemoveCardPopup}
                            />
                        ),
                        DropdownIndicator: (): JSX.Element => <ChevronIconWrapper position='down' />,
                    }}
                    isSearchable={false}
                />
            </SelectInputContainer>
            <DeleteCardPopup deleteCardPopupState={deleteCardPopupState} />
        </>
    );
});
