import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface CheckCircleFilledIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CheckCircleFilledIcon = observer(
    'CheckCircleFilledIcon',
    ({ className, onClick }: CheckCircleFilledIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#13D26C'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
                data-test='check-circle-filled-icon'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM9.42975 17.4562C9.62874 17.4562 9.85426 17.3253 10.0134 17.1681L18.875 8.37286L17.7142 7.1875L9.43638 15.4195L6.30566 12.3284L5.125 13.4941L8.83942 17.1615C9.00525 17.3187 9.23076 17.4562 9.42975 17.4562Z'
                    fill='#13D26C'
                />
            </SvgElement>
        );
    }
);
