import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Slider } from 'src/domains/casino/webview/CasinoGames/home-games/slider/Slider';
import { GamesWrapper } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesHome.style';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { SingleGameExt } from 'src/domains/casino/webview/CasinoGames/single-game-ext/SingleGameExt';
import { GameNumericImgWrapper, GameNumericWrapper, SlideNumberIcon1, SlideNumberIcon10, SlideNumberIcon2, SlideNumberIcon3,
    SlideNumberIcon4, SlideNumberIcon5, SlideNumberIcon6, SlideNumberIcon7, SlideNumberIcon8, SlideNumberIcon9
} from './GamesNumericSlider.style';

interface GamesSliderPropsType {
    appCasinoState: AppCasinoState,
    title: string | JSX.Element,
    gameModels: Array<CasinoGameModelExt>,
}

export const GamesNumericSlider = observer('GamesNumericSlider', (props: GamesSliderPropsType) => {
    const { appCasinoState, gameModels, title } = props;

    if (gameModels.length === 0) {
        return null;
    }

    const showSlideNumberIcon = (slideNumber: number): JSX.Element | null => {
        switch (slideNumber) {
            case 1: return <SlideNumberIcon1 />;
            case 2: return <SlideNumberIcon2 />;
            case 3: return <SlideNumberIcon3 />;
            case 4: return <SlideNumberIcon4 />;
            case 5: return <SlideNumberIcon5 />;
            case 6: return <SlideNumberIcon6 />;
            case 7: return <SlideNumberIcon7 />;
            case 8: return <SlideNumberIcon8 />;
            case 9: return <SlideNumberIcon9 />;
            case 10: return <SlideNumberIcon10 />;
        }
        return null;
    };

    return (
        <Slider appCasinoState={appCasinoState} title={title} withoutViewAll={true}>
            <GamesWrapper>
                {gameModels.map((gameModel, index) => {
                    const slideNumber = index + 1;

                    return (
                        <GameNumericWrapper
                            key={gameModel.id}
                            title={gameModel.name}
                        >
                            {showSlideNumberIcon(slideNumber)}
                            <GameNumericImgWrapper>
                                <SingleGameExt
                                    appCasinoState={appCasinoState}
                                    key={gameModel.id}
                                    gameModel={gameModel}
                                    wrapperType='numeric-trending'
                                />
                            </GameNumericImgWrapper>
                        </GameNumericWrapper>
                    );
                })}
            </GamesWrapper>
        </Slider>
    );
});
