import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons/FreeBetIcon';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { withConfig } from 'src/withConfig';
import { CheckCircleFilledIcon } from 'src/domains/layouts/shared/assets/icons/CheckCircleFilledIcon';

const fadeInReceipt = keyframes`
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 400px;
        opacity: 1;
    }
`;

export const BetslipReceiptWrapper = withConfig(theme => styled('div', { label: 'BetslipReceiptWrapper' })`
    animation: 0.4s ${fadeInReceipt} 1;
    background-color: ${theme.star.betSlipReceipt.bgColor};
    color: ${theme.star.betSlipReceipt.txtColor};
    height: 100%;
    padding: 20px 0;
    text-align: center;
    width: 100%;
`);

export const BetslipReceiptHeader = withConfig(theme => styled('header', { label: 'BetslipReceiptHeader' })`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    margin-bottom: 28px;
`);

export const BetslipReceiptIconWrapper = styled(CheckCircleFilledIcon, { label: 'BetslipReceiptIconWrapper' })`
    display: block;
    fill: currentcolor;
    margin: 4px auto;
    width: 36px;
`;

export const BetslipReceiptGiftIconWrapper = styled(FreeBetIcon, { label: 'BetslipReceiptGiftIconWrapper' })`
    fill: currentcolor;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
    width: 14px;
`;

export const Text = withConfig(theme => styled('p', { label: 'Text' })`
    color: ${theme.star.betSlipReceipt.txtColorQuaternary};
    line-height: 1.5;
    margin: 0 25px;
`);

export const ListWrapper = styled('ul', { label: 'ListWrapper' })`
    margin: 25px 0;
    text-align: left;
`;

export const ListElement = withConfig(theme => styled('li', { label: 'ListElement' })`
    color: ${theme.star.betSlipReceipt.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4;
    padding: 8px 10px;
`);

export const ReceiptValue = withConfig(theme => styled('span', { label: 'ReceiptValue' })`
    float: right;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-top: -3px;
`);

export const SmallText = withConfig(theme => styled('span', { label: 'SmallText' })`
    color: ${theme.star.betSlipReceipt.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.xxSmall};
`);

export const BonusEngineFreeBetExcludedReturnsText = withConfig(theme => styled('div', { label: 'BonusEngineFreeBetExcludedReturnsText' })`
    margin-top: 24px;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    color: #1111117a;
    justify-self: start;
    padding: 0 16px;
`);
