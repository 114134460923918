import { action, observable, makeObservable } from 'mobx';

export class LiveTrackerIframeState {
    @observable public isOpen: boolean;

    public constructor(isOpen: boolean) {
        makeObservable(this);

        this.isOpen = isOpen;
    }

    @action public toggle = (): void => {
        this.isOpen = !this.isOpen;
    };
}
