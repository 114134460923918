import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon6 = observer('NumberIcon6', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 103 128'

            width='103'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M84.2775 37.2128C77.6015 32.0946 69.7573 29.5355 60.7448 29.5355C52.6224 29.5355 46.4471 31.872 42.2191 36.5452C38.1022 41.1071 36.0994 47.6718 36.2107 56.2392C38.8811 52.6787 42.3303 49.9527 46.5584 48.0612C50.8978 46.0584 55.9604 45.057 61.7462 45.057C69.201 45.057 75.71 46.6704 81.2733 49.8971C86.8366 53.0125 91.1203 57.4631 94.1245 63.249C97.2399 69.0348 98.7976 75.7107 98.7976 83.2768C98.7976 91.5104 96.9617 98.6871 93.29 104.807C89.7295 110.926 84.6113 115.655 77.9353 118.993C71.3706 122.331 63.749 124 55.0702 124C44.6113 124 35.5987 121.663 28.0327 116.99C20.4666 112.206 14.6252 105.419 10.5083 96.6286C6.50278 87.8387 4.5 77.491 4.5 65.5855C4.5 53.235 6.72531 42.4423 11.1759 33.2072C15.6266 23.8609 21.9131 16.6843 30.0355 11.6773C38.2691 6.55911 47.838 4 58.742 4C65.5292 4 72.372 4.89013 79.2705 6.67038C86.169 8.45063 92.0661 10.8985 96.9617 14.0139L84.2775 37.2128ZM54.0688 69.2573C49.3957 69.2573 45.7796 70.5369 43.2204 73.096C40.7726 75.6551 39.5487 79.1043 39.5487 83.4437C39.5487 87.6718 40.717 91.0654 43.0535 93.6245C45.5014 96.1836 49.0619 97.4631 53.735 97.4631C58.2969 97.4631 61.8018 96.2392 64.2496 93.7914C66.6975 91.3435 67.9214 87.783 67.9214 83.1099C67.9214 78.7705 66.6975 75.3769 64.2496 72.9291C61.9131 70.4812 58.5195 69.2573 54.0688 69.2573Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

