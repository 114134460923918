import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { GamesNumericSlider } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/games-numeric-slider/GamesNumericSlider';
import { GamesGridCasino } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesGrid';
import { CasinoBanners } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/CasinoBanners';
import {
    GamesSliderCasino,
    GamesSliderMultiCollection,
} from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesSlider';
import { GamesHomeWrapper } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesHome.style';
import { extractUserFirstName } from 'src/domains/casino/utils/utils';
import { CollectionSlider } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/collection-slider/CollectionSlider';
import { useCommon } from 'src/domains/common/Common';
import { InfiniteScrollWrapper } from 'src/domains/casino/webview/infiniteScrollWrapper/InfiniteScrollWrapper';

interface CasinoHomePropsType {
    appCasinoState: AppCasinoState;
}

export const CasinoHome = observer('CasinoHome', (props: CasinoHomePropsType) => {
    const { appCasinoState } = props;
    const { features } = appCasinoState;
    const common = useCommon();
    const authorizedUserName = extractUserFirstName(common.session.loggedUserName) ?? '';
    const continuePlayingName = features.config.hasContinuePlayingName === true ? authorizedUserName : '';

    return (
        <GamesHomeWrapper>
            <GamesSliderMultiCollection
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.continue-playing'
                        defaultText='Continue Playing {userName}'
                        params={{ userName: continuePlayingName }}
                    />
                }
                gameModels={appCasinoState.homeCollections.recentGameModels}
                withoutViewAll={true}
            />
            <GamesSliderCasino
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.popular'
                        defaultText='Popular Games'
                    />
                }
                gameModels={appCasinoState.homeCollections.casinoPopularGames.gameModels}
                onViewAllClick={appCasinoState.homeCollections.casinoPopularGames.redirect}
            />
            <GamesGridCasino
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.new'
                        defaultText='New Games'
                    />
                }
                gameModels={appCasinoState.homeCollections.casinoNewGames.gameModels}
                onViewAllClick={appCasinoState.homeCollections.casinoNewGames.redirect}
            />
            <GamesNumericSlider
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.trending'
                        defaultText='Trending'
                    />
                }
                gameModels={appCasinoState.homeCollections.casinoTrendingGames.gameModels}
            />
            <CasinoBanners
                collection='casino'
                appCasinoState={appCasinoState}
            />
            <CollectionSlider
                collection='live-casino'
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.live-casino'
                        defaultText='Live Casino'
                    />
                }
                gameModels={appCasinoState.homeCollections.liveCasinoPopularGames.gameModels}
                onViewAllClick={appCasinoState.redirectState.redirectToLiveCasinoPage}
            />
            <GamesSliderCasino
                appCasinoState={appCasinoState}
                title={
                    <I18n
                        langKey='casino.home.category.name.table'
                        defaultText='Table Games'
                    />
                }
                gameModels={appCasinoState.homeCollections.casinoTableGames.gameModels}
                onViewAllClick={appCasinoState.homeCollections.casinoTableGames.redirect}
            />
            <InfiniteScrollWrapper
                initAmount={3}
                increaseAmount={2}
            >
                {appCasinoState.homeCollections.casinoCustomGames.map((collection) => (
                    <GamesGridCasino
                        appCasinoState={appCasinoState}
                        key={collection.categoryModel?.id}
                        title={collection.categoryModel?.name === undefined ? '' : collection.categoryModel.name}
                        gameModels={collection.gameModels}
                        onViewAllClick={collection.redirect}
                    />
                ))}
            </InfiniteScrollWrapper>
        </GamesHomeWrapper>
    );
});
