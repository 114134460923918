import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, CasinoPageState } from 'src/domains/casino/state/AppCasino.state';
import { CasinoFilters } from 'src/domains/casino/webview/CasinoGames/casino-filters/CasinoFilters';
import { CasinoNavigationLoader } from './CasinoNavigationLoader';
import {
    SearchBtn,
    SearchIconWrapper,
    ChevronIconWrapper,
    SearchInputWrapper,
    SearchInput,
    SearchCloseButton,
    SearchAndFilterWrapper,
} from 'src/domains/casino/webview/CasinoGames/casino-filters/CasinoFilters.style';

interface CasinoNavigationPropsType {
    appCasinoState: AppCasinoState;
    casinoPageState: CasinoPageState;
    hideCategories: boolean;
}

export const CasinoNavigation = observer('CasinoNavigation', (props: CasinoNavigationPropsType) => {
    const { appCasinoState, casinoPageState } = props;
    const { features } = appCasinoState;
    const placeholder = appCasinoState.languagesState.getTranslation(
        'casino.search-bar.placeholder',
        'Search For Games'
    );
    const inputKey = `${casinoPageState.casinoSearchExt.isSearchOpen.toString()}`;

    if (casinoPageState.showCategoryLoader) {
        return <CasinoNavigationLoader hideCategories={props.hideCategories} />;
    }

    return (
        <SearchAndFilterWrapper
            isDropdownOpened={casinoPageState.casinoSearchExt.isDropdownOpen}
            layout={features.config.layout}
            isMiniGamesListDisplayed={appCasinoState.miniGamesListState.isGamesListDisplayed}
            ref={casinoPageState.setNavRef}
            stickyDesktopHeader={features.config.stickyDesktopHeader}
            data-test='sticky-bar'
        >
            <SearchBtn
                size='medium'
                type='button'
                onClick={casinoPageState.toggleSearchInput}
                dataTest='casino-search-button'
            >
                <SearchIconWrapper isSearchOpen={casinoPageState.casinoSearchExt.isSearchOpen} />
                <ChevronIconWrapper
                    isSearchOpen={casinoPageState.casinoSearchExt.isSearchOpen}
                    position='left'
                />
            </SearchBtn>
            <SearchInputWrapper
                isSearchOpen={casinoPageState.casinoSearchExt.isSearchOpen}
                layout={features.config.layout}
                data-test='search-input-wrapper'
            >
                <SearchInput
                    autoFocus={casinoPageState.casinoSearchExt.isSearchOpen}
                    key={inputKey}
                    onChange={casinoPageState.onInputChange}
                    placeholder={placeholder}
                    ref={casinoPageState.setInputRef}
                    type='search'
                    value={casinoPageState.searchInputValue}
                    data-test='casino-search-input'
                />
                <SearchCloseButton onClick={casinoPageState.clearQueryOrClose} />
            </SearchInputWrapper>
            {props.hideCategories === true ? null : (
                <CasinoFilters
                    appCasinoState={appCasinoState}
                    casinoPageState={casinoPageState}
                />
            )}
        </SearchAndFilterWrapper>
    );
});
