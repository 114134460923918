import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    WithdrawForm,
    BalanceRowWrapper,
    WithdrawInput,
    BalanceWrapper,
    BalanceValue,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure.style';
import { WithdrawFormState } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/WithdrawFormState';
import { observer } from 'src/utils/mobx-react';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { YaspaSegment } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaSegment/YaspaSegment';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { useCommon } from 'src/domains/common/Common';
import { WithdrawCardSegment } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawCardSegment';

const preventDefault = (event: React.FormEvent<HTMLFormElement>): void => event.preventDefault();

interface WithdrawProcedurePropType {
    state: WithdrawFormState;
    paymentMethodDropdownState: PaymentMethodDropdownState;
}

export const WithdrawProcedure = observer(
    'WithdrawProcedure',
    ({ state, paymentMethodDropdownState }: WithdrawProcedurePropType) => {
        const common = useCommon();
        const moneySymbol = BasicDataModel.get(common).moneySymbol;

        return (
            <>
                <BalanceWrapper>
                    <span data-test='withdrawable-balance-label'>
                        <I18n
                            langKey='account.withdraw.cash-balance-label'
                            defaultText='Withdrawable balance'
                        />
                    </span>
                    <BalanceValue data-test='balance-value'>{state.showBalance}</BalanceValue>
                </BalanceWrapper>

                <WithdrawForm onSubmit={preventDefault}>
                    <BalanceRowWrapper key='payment-rows'>
                        <WithdrawInput
                            type='text'
                            maxLength={10}
                            placeholder='0.00'
                            label={
                                <I18n
                                    langKey='account.withdraw.description-label'
                                    defaultText='Amount to withdraw'
                                />
                            }
                            currency={moneySymbol}
                            state={state.amountState}
                            onChange={state.handleAmountInputChange}
                            onBlur={state.setAmount}
                            colorTheme='light'
                            inputHasBackground={false}
                            dataTest='withdraw-amount-input'
                        />
                    </BalanceRowWrapper>
                    <PaymentAdditions
                        key='payment-additions'
                        value={state.amountState.value}
                        currency={moneySymbol}
                        onChange={state.handlePaymentAdditionsChange}
                    />
                    {paymentMethodDropdownState.areSavedPaymentMethods ? (
                        <WithdrawCardSegment
                            state={state}
                            paymentMethodDropdownState={paymentMethodDropdownState}
                        />
                    ) : null}

                    {paymentMethodDropdownState.savedOpenBankingMethods.length > 0 ? (
                        <YaspaSegment
                            viewType='withdraw'
                            depositYaspaPage={state.depositYaspaPage}
                            showSeparator={paymentMethodDropdownState.areSavedPaymentMethods}
                        />
                    ) : null}
                </WithdrawForm>
            </>
        );
    }
);
