import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { Checkbox } from 'src/domains/sportsbook/betting/ui/checkbox/CheckBox';
import { InfoMessageDeprecated } from 'src/domains/layouts/webview/components/Messages';
import { Amount } from 'src_common/common/amount/Amount';
import { BetslipCombinationId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import {
    EachWayBoxLabel,
    TitleWrapper,
    BetSlipError,
    BetSlipSelectionWrapper,
    BonusButton,
    AppliedFreeBetContainer,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.style';
import {
    ReturnWrapper,
    CombinationName,
    InputWrapper,
    ReturnValue,
    EachWayBox,
    ReturnsWrapper,
} from './BetslipCombination.style';
import { useCommon } from 'src/domains/common/Common';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { RewardWalletIcon } from 'src/domains/layouts/shared/assets/iconography/betslip/RewardWallet';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

interface PropsType {
    selectionId: BetslipCombinationId;
    isHidden?: boolean;
}

export const BetslipCombination = observer('BetslipCombination', (props: PropsType) => {
    const common = useCommon();
    const { money } = BasicDataModel.get(common);
    const { appSportsBookState } = useAppStateContext();
    const { selectionId, isHidden } = props;
    const { legsState, betSlipSummaryState, referralState, betslipData, openBonusPopup, getSelectionAppliedBonuses } =
        appSportsBookState.betSlipState;
    const combinationId = selectionId;
    const combinationElem = combinationId.getModel();

    if (combinationElem === null) {
        return null;
    }

    const {
        combination,
        eachWay,
        errors,
        ewOffered,
        handleChangeEachWay,
        name,
        numLines,
        returns,
        stakePerLine,
        freeBets,
    } = combinationElem;

    const appliedBonusFreeBetAmount = getSelectionAppliedBonuses(combinationElem.id);

    const potentialReturns = (): React.ReactElement | null => {
        const { isAnyLegSP } = legsState;
        const { betTooHighValue } = betSlipSummaryState;
        const isStake = stakePerLine !== null && stakePerLine.isGreaterThanZero();

        return (
            <ReturnsWrapper>
                <ReturnWrapper data-test='returns'>
                    <I18n
                        langKey='betslip.star-return'
                        defaultText='Returns:'
                    />
                    <ReturnValue>
                        {combination !== null &&
                        combination.totalStake !== null &&
                        new Amount(combination.totalStake).isEqualWith(new Amount('0')) &&
                        !isAnyLegSP
                            ? money(new Amount('0'))
                            : isAnyLegSP || (combination !== null && combinationId.isCastCombination())
                              ? money(null)
                              : money(returns)}{' '}
                    </ReturnValue>
                </ReturnWrapper>

                {betTooHighValue !== null && isStake ? (
                    <ReturnWrapper>
                        <I18n
                            langKey='betslip.star-max-bet'
                            defaultText='Max. bet:'
                        />
                        <ReturnValue>{money(betTooHighValue)}</ReturnValue>
                    </ReturnWrapper>
                ) : null}
            </ReturnsWrapper>
        );
    };

    const labelWrapper = (
        <EachWayBoxLabel>
            <I18n
                langKey='betslip.combination.each-way.label'
                defaultText='E/W'
            />
        </EachWayBoxLabel>
    );

    const translationsStore = TranslationsStore.get(common);

    return (
        <BetSlipSelectionWrapper
            ref={betslipData.setRef(selectionId)}
            isHidden={isHidden}
            data-test-combinations-selection-name={name}
        >
            <TitleWrapper>
                <CombinationName data-test='combination-name'>
                    <>
                        {translationsStore.getBetslipTranslation(name)} {numLines === null ? null : ` x ${numLines}`}
                    </>
                </CombinationName>
            </TitleWrapper>

            <InputWrapper>
                <StakeInputMobx selectionId={combinationId} />
                {((ewOffered !== null && ewOffered) || (eachWay !== null && eachWay)) &&
                !combinationId.isCastCombination() ? (
                    <EachWayBox data-test='ew-checkbox'>
                        <Checkbox
                            label={labelWrapper}
                            isChecked={eachWay !== null && eachWay}
                            isDisabled={referralState.isReferred}
                            handleOnChange={handleChangeEachWay}
                        />
                    </EachWayBox>
                ) : null}
                {common.features.bonuseEngine && freeBets !== null && freeBets.length > 0 && (
                    <BonusButton
                        size='medium'
                        data-test='combination-bonus-button'
                        onClick={(): Promise<void> => openBonusPopup(combinationElem.id, freeBets, true)}
                    >
                        <RewardWalletIcon />
                    </BonusButton>
                )}
            </InputWrapper>
            {common.features.bonuseEngine && appliedBonusFreeBetAmount !== null && (
                <AppliedFreeBetContainer>
                    <RewardWalletIcon /> {money(appliedBonusFreeBetAmount)} Free Bet Applied
                </AppliedFreeBetContainer>
            )}

            {potentialReturns()}
            {errors.length > 0 ? (
                <BetSlipError>
                    {errors.map((error): JSX.Element | null => {
                        if (error.code === 'singles-only') {
                            return null;
                        }
                        return (
                            <InfoMessageDeprecated
                                message={error}
                                hasIcon={false}
                                key={`${error.code}-${error.resource}`}
                            />
                        );
                    })}
                </BetSlipError>
            ) : null}
        </BetSlipSelectionWrapper>
    );
});
