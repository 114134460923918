import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { css, SerializedStyles } from '@emotion/react';



const checkboxWrapper = (theme: EmotionTheme, signUpCheckboxTC: boolean): SerializedStyles => {

    if (signUpCheckboxTC === true) {

        return css`

            margin-top: 16px;

        `;

    }



    return css`

        border: 1px solid ${theme.star.signUp.borderColor};

        color: ${theme.star.signUp.txtColor};

        font-size: ${theme.star.fontSize.small};

        line-height: 1.3334;

        margin-top: 24px;

        padding: 16px;

    `;

};



interface InfoWrapperType {

    signUpCheckboxTC: boolean;

}

export const InfoWrapper = withConfig(theme => styled('div', { label: 'InfoWrapper' }) <InfoWrapperType>`

    ${({ signUpCheckboxTC }): SerializedStyles => checkboxWrapper(theme, signUpCheckboxTC)};

`);



export const TermsButton = withConfig(theme => styled('button', { label: 'TermsButton' })`

    background-color: transparent;

    border: 0;

    color: ${theme.star.signUp.termsAndConditions.txtColor};

    cursor: pointer;

    font-weight: ${theme.star.fontWeight.bold};

    padding: 0;

    text-decoration: none;

`);





export const InfoContentWrapper = withConfig(theme => styled('div', { label: 'InfoContentWrapper' })`

    background-color: ${theme.star.staticPage.bgColor};

    color: ${theme.star.layoutGlobal.txtColor};

    left: 0;

    position: absolute;

    top: 0;

    width: 100%;

    z-index: ${theme.star.zIndexGlobal.above};

    height: 100%;

    overflow: auto;

`);



interface InfoContentTitleType {

    hasArrow?: boolean;

}



export const InfoContentTitle = withConfig(theme => styled('div', { label: 'InfoContentTitle' })<InfoContentTitleType>`

    align-items: center;

    background-color: ${theme.star.signUp.bgColor};

    color: ${theme.star.signUp.txtColor};

    display: flex;

    font-size: ${theme.star.fontSize.regular};

    font-weight: ${theme.star.fontWeight.bold};

    height: 48px;

    justify-content: ${({ hasArrow }): string => hasArrow === true ? "flex-start" : "space-between"};

    padding: 16px;

    ${({ hasArrow }): string | null => hasArrow === true ? "padding-left: 0" : null};

    text-transform: uppercase;

`);



export const InfoContent = withConfig(theme => styled('div', { label: 'InfoContent' })`

    padding: 20px;

    color: ${theme.star.form.txtColor};

`);



export const CloseButton = styled('button', { label: 'CloseButton' })`

    background-color: transparent;

    border: 0;

    height: 20px;

    outline: none;

    padding: 0;

    position: relative;

    width: 20px;

`;



export const StyledCloseIcon = withConfig(theme => styled(CloseIcon, { label: 'StyledCloseIcon' })`

    cursor: pointer;

    fill: ${theme.star.signUp.termsAndConditions.bgColor};

    width: 20px;

`);

export const CheckboxWrapper = styled('div', { label: 'CheckboxWrapper' })`

    margin-bottom: 8px;

`;

