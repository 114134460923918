import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { Card } from 'src/domains/players/webview/components/Account/common/card/Card';
import { BadgeGroup } from 'src/domains/players/webview/components/Account/common/badge/BadgeGroup';
import {
    CreditsCardButton,
    CreditsCardConditionsContainer,
    CreditsCardContainer,
    CreditsCardHeader,
    CreditsCardBadgeExpiryAt,
    CreditsCardRow,
    CreditsCardRowLabel,
    CreditsCardRowValue,
    CreditsCardSubHeader,
    ButtonsWrapper,
} from './Credits.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Badge } from 'src/domains/players/webview/components/Account/common/badge/Badge';
import { BonusViewType } from 'src/api_openapi/v2/bonus-engine/schemas';
import { CreditsState } from './Credits.state';
import { GameSharedModel } from 'src/domains/casino/state/AppCasino.state';
import { TermsPage } from 'src/domains/players/webview/components/SignUp';
import { TermsDrawerState } from './TermsDrawer/TermsDrawer.state';
import { useCommon } from 'src/domains/common/Common';
import { useAppStateContext } from 'src/appState/AppState';

interface CreditsBetSpinsCardPropsType {
    bonus: BonusViewType;
    creditsState: CreditsState;
    getGameModelByLaunchGameId: (gameId: string | number) => GameSharedModel | null;
}

export const CreditsBetSpinsCard = observer(
    'CreditsBetSpinsCard',
    ({ bonus, creditsState, getGameModelByLaunchGameId }: CreditsBetSpinsCardPropsType) => {
        const common = useCommon();
        const {
            appPlayersState: { customKeyboard },
        } = useAppStateContext();
        const [state] = useState<TermsDrawerState>(() => new TermsDrawerState(common, customKeyboard));
        const campaignId = bonus.campaignId ?? null;
        return (
            <Card haveBorder={true}>
                {state.displayTermsPage ? (
                    <TermsPage
                        title='TERMS & CONDITIONS'
                        content={state.terms ?? 'Error occurred'}
                        closePage={state.closePage}
                        hasArrow
                    />
                ) : null}
                <CreditsCardContainer>
                    <div>
                        <CreditsCardHeader>
                            <div>{creditsState.getCreditValue(bonus.currentAmount ?? 0)}</div>
                            <div>
                                {bonus.status === 'active' ? (
                                    <CreditsCardBadgeExpiryAt>
                                        <I18n
                                            langKey='account.credits.badge.expires-at'
                                            defaultText='Expires'
                                        />{' '}
                                        {creditsState.formatExpires(bonus.expiryAt, 'badge')}
                                    </CreditsCardBadgeExpiryAt>
                                ) : (
                                    <Badge type={creditsState.mapBadgeTypeBasedOnStatus(bonus.status)}>
                                        {bonus.status}
                                    </Badge>
                                )}
                            </div>
                        </CreditsCardHeader>
                        {bonus.status === 'active' ? null : (
                            <CreditsCardSubHeader>{creditsState.formatExpires(bonus.expiryAt)}</CreditsCardSubHeader>
                        )}
                    </div>
                    <CreditsCardConditionsContainer>
                        {creditsState.filterConditions(bonus, getGameModelByLaunchGameId)?.map((condition) => {
                            return (
                                <CreditsCardRow key={condition.type}>
                                    <CreditsCardRowLabel>{condition.type}:</CreditsCardRowLabel>

                                    {typeof condition.value === 'string' ||
                                    typeof condition.value === 'number' ||
                                    typeof condition.value === 'boolean' ? (
                                        <CreditsCardRowValue>{condition.value}</CreditsCardRowValue>
                                    ) : (
                                        <BadgeGroup badges={condition.value} />
                                    )}
                                </CreditsCardRow>
                            );
                        })}
                        {/* <CreditsCardRowValue>{bonus.playthrough}x</CreditsCardRowValue> */}
                    </CreditsCardConditionsContainer>
                    {creditsState.creditType === 'free-bets' ? (
                        <ButtonsWrapper>
                            {campaignId === null ? null : (
                                <CreditsCardButton
                                    size='small'
                                    isLeft
                                    onClick={(): void => state.showTermsPage(bonus.campaignId)}
                                    version='tertiary-dark'
                                >
                                    <I18n
                                        langKey='account.credits.view-terms'
                                        defaultText='View Terms'
                                    />
                                </CreditsCardButton>
                            )}

                            <CreditsCardButton
                                size='small'
                                onClick={creditsState.redirectToSportsbook}
                                disabled={creditsState.isButtonDisabled(bonus.status)}
                            >
                                <I18n
                                    langKey='account.credits.bet-now'
                                    defaultText='Bet Now'
                                />
                            </CreditsCardButton>
                        </ButtonsWrapper>
                    ) : (
                        <ButtonsWrapper>
                            {campaignId === null ? null : (
                                <CreditsCardButton
                                    size='small'
                                    isLeft
                                    onClick={(): void => state.showTermsPage(bonus.campaignId)}
                                    version='tertiary-dark'
                                >
                                    <I18n
                                        langKey='account.credits.view-terms'
                                        defaultText='View Terms'
                                    />
                                </CreditsCardButton>
                            )}
                            <CreditsCardButton
                                size='small'
                                onClick={creditsState.redirectToCasino}
                                disabled={creditsState.isButtonDisabled(bonus.status)}
                            >
                                <I18n
                                    langKey='account.credits.play-now'
                                    defaultText='Play Now'
                                />
                            </CreditsCardButton>
                        </ButtonsWrapper>
                    )}
                </CreditsCardContainer>
            </Card>
        );
    }
);
