import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import {
    SignUpWelcomeState,
    WelcomeStepFormType,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/signupWelcome/SignupWelcome.state';
import {
    GenderFormModelType,
    YourDetailsFormType,
    YourDetailsState,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.state';
import { action, observable, makeObservable } from 'mobx';
import { getCookie, setCookie } from 'src/domains/layouts/config/config';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUpParams';
import { isCountryCodeType } from 'src/domains/layouts/config/countries';
import { DateTime } from 'src_common/utils/time/time';
import { createAccountPrimary } from 'src_server/trpc/types';
import { CurrencyType, isCurrencyCode } from 'src_common/common/amount/website-money/currency';

const AccountAgeVerificationEnum = createAccountPrimary.AccountAgeVerificationEnum;
const AccountStatusEnum = createAccountPrimary.AccountStatusEnum;

interface SignUpStepsGroupModelType {
    signupWelcomeState: WelcomeStepFormType;
    yourDetailsState: YourDetailsFormType;
    titleState: GenderFormModelType;
}

interface CallbacksType {
    readonly redirectToVerificationFailed: () => void;
    readonly redirectToGamStopAccountFailed: () => void;
    readonly redirectToNextStep: () => void;
    readonly setLoading: (isLoading: boolean) => void;
    readonly resetPromoCodeInLocalStorage: () => void;
}

export type CreateAccountStageType = 'welcome' | 'your-details';

export class CreateAccountStep {
    @observable public backendCreateAccountError: string | null = null;
    @observable public currentStage: CreateAccountStageType;

    public welcomeState: SignUpWelcomeState;
    public yourDetailsState: YourDetailsState;

    @observable.ref public signUpFormModel: FormModel<createAccountPrimary.CreateAccountInputType>;
    @observable.ref private signUpStepsGroup: FormModel<SignUpStepsGroupModelType>;

    public constructor(
        public readonly params: SignUpParamsType,
        private readonly callbacks: CallbacksType,
        private stage?: CreateAccountStageType,
        private readonly initialUserData?: WelcomeStepFormType
    ) {
        makeObservable(this);
        this.currentStage = this.stage === undefined ? 'welcome' : this.stage;

        this.welcomeState = new SignUpWelcomeState(
            this.params.customKeyboard,
            this.moveToNextStage,
            params.language,
            params.common,
            this.initialUserData
        );
        this.yourDetailsState = new YourDetailsState(
            this.params.customKeyboard,
            this.params.language,
            this.params.config,
            this.params.geolocalization,
            this.params.common.trpcClient
        );

        this.signUpStepsGroup = FormModel.group({
            signupWelcomeState: this.welcomeState.welcomeStepFormModel,
            yourDetailsState: this.yourDetailsState.yourDetailsFormModel,
            titleState: this.yourDetailsState.genderTitleForModel,
        });

        this.signUpFormModel = this.signUpStepsGroup.map(
            (value): Result<createAccountPrimary.CreateAccountInputType> => {
                const btag = getCookie('btag');
                const stag = getCookie('stag');
                const referrer = getCookie('referrer');

                const countryKey = this.yourDetailsState.country.currentValueFormatted?.key ?? '';
                const mobilePhoneCountryKey = this.yourDetailsState.phoneNumber.prefix.currentValueFormatted?.key ?? '';
                const currency = value.yourDetailsState.currency;
                const defCurrency: CurrencyType = 'EUR';

                const createAccountData: createAccountPrimary.CreateAccountInputType = {
                    email: value.signupWelcomeState.email,
                    password: value.signupWelcomeState.password,
                    title: value.titleState.genderTitle,
                    firstName: value.yourDetailsState.firstName,
                    surname: value.yourDetailsState.lastName,
                    birthDate: value.yourDetailsState.dateOfBirth.format('YYYY-MM-DD'),
                    currency: isCurrencyCode(currency) ? currency : defCurrency,
                    country: isCountryCodeType(countryKey) ? countryKey : 'GB',
                    contactPreferences: value.yourDetailsState.contactPreferences,
                    postCode: value.yourDetailsState.postcode,
                    addressLine1: value.yourDetailsState.addressLine1,
                    addressLine2: this.params.config.config.signUpSecondAddressLine
                        ? value.yourDetailsState.addressLine2
                        : undefined,
                    city: value.yourDetailsState.city,
                    mobilePhone: {
                        country: isCountryCodeType(mobilePhoneCountryKey) ? mobilePhoneCountryKey : 'GB',
                        prefix: value.yourDetailsState.prefix.replace('+', ''),
                        number: value.yourDetailsState.phoneNumber,
                    },
                    referrer: referrer ?? undefined,
                    incomeaccess: value.signupWelcomeState.affiliateId ?? btag ?? stag ?? undefined,
                    promoID: value.signupWelcomeState.promoCode,
                };

                if (value.signupWelcomeState.affiliateId !== undefined) {
                    setCookie('btag', value.signupWelcomeState.affiliateId, 31, 'None');
                }

                return Result.createOk(createAccountData);
            }
        );
    }

    @action private onCreateAccount = async (): Promise<boolean> => {
        this.signUpStepsGroup.setAsVisited();
        const fullFormResult = this.signUpFormModel.result;
        await this.welcomeState.promoCodesState.promoCodesResource.refresh();

        if (fullFormResult.value.type === 'ok') {
            this.yourDetailsState.loadingCreateAccountReq = true;
            const email = this.welcomeState.emailState.value;
            const password = this.welcomeState.passwordState.value;

            if (this.welcomeState.promoCodesState.promoCodes.type === 'ready') {
                const isPromoCodeValid = this.welcomeState.promoCodesState.checkIfCodeExistsAndIsValid(
                    this.welcomeState.promoCodeTextState.value
                );

                if (isPromoCodeValid === false) {
                    const message = this.params.language.getTranslation(
                        'sign-up.create-account.promo-code.error',
                        'This promo code is not valid'
                    );
                    this.backendCreateAccountError = message;
                    this.yourDetailsState.loadingCreateAccountReq = false;
                    return false;
                }
            }

            const createAccountResponse = await this.params.common.trpcClient.client.signup.createAccountDefault.mutate(
                fullFormResult.value.data
            );

            this.yourDetailsState.loadingCreateAccountReq = false;

            if (createAccountResponse.responseStatus === 'error_input') {
                const arrEl = createAccountResponse.data.errors[0];

                if (arrEl?.field !== undefined && arrEl.field !== null) {
                    if (arrEl.debugDetails !== undefined && arrEl.debugDetails !== null) {
                        this.backendCreateAccountError = `In ${arrEl.field} - ${arrEl.debugDetails}`;
                    }
                }
                console.error('onCreateAccount - error1', createAccountResponse.data);
                return false;
            } else if (createAccountResponse.responseStatus === 'error_api') {
                const errorCode = createAccountResponse.data.code;
                if (errorCode === 'single_license_constraint') {
                    this.backendCreateAccountError = this.params.language.getTranslation(
                        'sign-up.create-account.error-single-license-constraint',
                        'Thank you for attempting to open an account with us. Having reviewed your details, we regret to inform you that you have already opened an account on our operating license and will not be able to open a further account at this time.'
                    );
                } else if (errorCode === 'cross_accounts_unavailable') {
                    this.backendCreateAccountError = this.params.language.getTranslation(
                        'sign-up.create-account.internal-server',
                        'Sorry, we seem to have a problem. Please try again later.'
                    );
                }
                return false;
            } else if (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                createAccountResponse.responseStatus === 'success' &&
                this.welcomeState.promoCodeTextState.value !== ''
            ) {
                await this.welcomeState.promoCodesState.updatePromoCode(this.welcomeState.promoCodeTextState.value);
            }
            //TODO: try to move AV to ProfileVerification.state. NEW INTEGRATION
            //if (createAccountResponse.responseStatus === 'success') {

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (createAccountResponse.responseStatus === 'success') {
                this.params.router.clearBtagAndStag();

                setCookie('btag', '', 0, 'None');
                setCookie('stag', '', 0, 'None');
            }

            this.callbacks.setLoading(true);
            this.callbacks.resetPromoCodeInLocalStorage();

            if (this.params.config.config.timeoutToTestSignupFlow) {
                await timeout(30000);
            }

            for (let i = 0; i < 150; i++) {
                //7min try login user, waiting for account verify.
                const accountStatus = await this.params.common.trpcClient.client.signup.checkStatusOfAccount.mutate({
                    body: { token: createAccountResponse.data.token },
                });

                if (accountStatus.responseStatus === 'success') {
                    const isBlockedAccount = accountStatus.data.status === AccountStatusEnum.BLOCKED;
                    const isClosedAccount = accountStatus.data.status === AccountStatusEnum.CLOSED;
                    const isActiveAccount = accountStatus.data.status === AccountStatusEnum.ACTIVE;
                    const isSuspendedAccount = accountStatus.data.status === AccountStatusEnum.SUSPENDED;
                    const isAgeVerificationPassed =
                        accountStatus.data.ageVerification === AccountAgeVerificationEnum.PASSED;
                    const isAvVerificationFailed =
                        accountStatus.data.ageVerification === AccountAgeVerificationEnum.FAILED;
                    const isAvVerificationUnknown =
                        accountStatus.data.ageVerification === AccountAgeVerificationEnum.UNKNOWN;

                    if (isSuspendedAccount || isBlockedAccount || isClosedAccount) {
                        const loginUser = await this.params.accountState.loginUser(email, password, 'registration');
                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                        }

                        if (loginUser.type === 'CreateSessionResponseErrors') {
                            this.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                            if (loginUser.error === 'gamstop_unauthorized') {
                                this.callbacks.setLoading(false);
                                this.callbacks.redirectToGamStopAccountFailed();
                                console.error('Verification error description: ', loginUser.error_description);
                                return false;
                            }
                        }
                    }

                    if (
                        (this.params.config.config.signUpOmitAgeVerification === true || isAgeVerificationPassed) &&
                        isActiveAccount
                    ) {
                        const loginUser = await this.params.accountState.loginUser(email, password, 'registration');

                        if (this.params.config.config.ringFencedFundsSetByDefault === true) {
                            // set by default RingFencedFlag for new user
                            await this.params.accountState.account?.onChangeRingFencedFlag();
                        }
                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                        }
                        return true;
                    }

                    if (
                        isBlockedAccount ||
                        isSuspendedAccount ||
                        isAvVerificationFailed ||
                        isAvVerificationUnknown ||
                        isClosedAccount
                    ) {
                        this.callbacks.setLoading(false);
                        this.callbacks.redirectToVerificationFailed();
                        return false;
                    }
                }

                await timeout(3000);
            }
        }

        return false;
    };

    @action public createAccountAndMoveToNextStep = async (): Promise<void> => {
        // shortcut to omit creating account ang move to responsible gambling
        // 1. go to http://localhost:3030/?account=signup
        // 2. uncomment
        // this.callbacks.redirectToNextStep();
        // return;
        // 3. signup with email but without user details - click Agree & Join

        if (this.params.config.config.zipCodeAndCountryCheck === true) {
            this.yourDetailsState.validateOnSubmit = true;
        }

        this.yourDetailsState.yourDetailsFormModel.setAsVisited();
        this.yourDetailsState.genderTitleForModel.setAsVisited();

        if (
            this.yourDetailsState.yourDetailsFormModel.result.value.type === 'error' ||
            (this.yourDetailsState.genderTitleForModel.result.value.type === 'error' &&
                this.params.config.config.signUpCheckboxTC === false)
        ) {
            this.yourDetailsState.showFullAddressFormHandler();
            return;
        }

        const createAccountStatus = await this.onCreateAccount();
        if (createAccountStatus) {
            this.callbacks.setLoading(false);
            this.callbacks.redirectToNextStep();
        } else {
            this.callbacks.setLoading(false);
        }
    };

    @action public moveToNextStage = (): void => {
        if (this.currentStage === 'welcome') {
            const date = DateTime.current().utc().format('YYYY-MM-DD HH:mm:ss [GMT]');

            this.params.googleTagManager.gtmSignUpStepOne(this.welcomeState.promoCodeValue, date);
            this.currentStage = 'your-details';
        } else {
            this.currentStage = 'welcome';
        }
    };
}
