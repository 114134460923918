import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, CasinoPageState } from 'src/domains/casino/state/AppCasino.state';
import { CollectionType } from 'src/domains/casino/types/types';
import { CasinoGames } from 'src/domains/casino/webview/CasinoGames/category-games/CasinoGames';
import { CasinoHome } from './home-content/CasinoHome';
import { LiveCasinoHome } from './home-content/LiveCasinoHome';
import { CasinoGamesLoader } from './category-games/CasinoGamesLoader';

interface HomeCollectionPropsType {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
}

const HomeCollection = observer('HomeCollection', (props: HomeCollectionPropsType): JSX.Element | null => {
    const { appCasinoState, collection } = props;

    if (appCasinoState.gameStoreExt.getCollection(collection).isLoading) {
        return <CasinoGamesLoader />;
    }

    if (collection === 'casino') {
        return <CasinoHome appCasinoState={appCasinoState} />;
    }

    if (collection === 'live-casino') {
        return <LiveCasinoHome appCasinoState={appCasinoState} />;
    }

    return null;
});

interface CasinoContentPropsType {
    appCasinoState: AppCasinoState;
    casinoPageState: CasinoPageState;
    collection: CollectionType;
}

export const CasinoContent = observer('CasinoContent', (props: CasinoContentPropsType): JSX.Element => {
    const { appCasinoState, casinoPageState, collection } = props;
    const { navigationState } = casinoPageState;

    if (navigationState.isHomeActive && navigationState.isSearchActive === false) {
        return (
            <HomeCollection
                appCasinoState={appCasinoState}
                collection={collection}
            />
        );
    }
    return (
        <CasinoGames
            appCasinoState={appCasinoState}
            gameModels={navigationState.gamesListToDisplay}
        />
    );
});
