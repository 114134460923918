import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const GamesHomeWrapper = styled('div', { label: 'GamesHomeWrapper' })`
    padding-bottom: 16px;
`;

export const GamesWrapper = styled('div', { label: 'GamesWrapper' })`
    align-items: flex-start;
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
    justify-content: flex-start;
`;

export const GridWrapper = withConfig(theme => styled('div', { label: 'GridWrapper' })`
    align-items: flex-start;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    justify-content: flex-start;
    margin-right: 16px;
    min-width: 288px;
    overflow-y: hidden;

    @media ${theme.star.mediaQuery.xxMobile} {
        grid-auto-flow: column;
        grid-template-columns: auto auto;
        margin-right: 0;
        overflow-y: initial;
    }
`);
