import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

interface YaspaSegmentType {
    isSignup: boolean;
}

export const YaspaIFrameStyled = styled(Iframe, { label: 'YaspaIFrameStyled' })`
    width: 100%;
    border: none;
    height: 100%;
`;

export const YaspaContainer = styled('div', { label: 'YaspaContainer' })`
    margin-top: 16px;
`;

export const YaspaDepositButton = styled(Button, { label: 'YaspaDepositButton' })`
    width: 100%;
`;

export const Separator = withConfig(theme => styled('div', { label: 'Separator' })<YaspaSegmentType>`
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ isSignup }): string => isSignup === true ? theme.star.topUp.txtColorQuaternary : theme.star.topUp.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.25;
    margin: 24px 0 24px 0;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid ${({ isSignup }): string => isSignup === true ? theme.star.topUp.txtColorQuaternary : theme.star.topUp.txtColorTertiary};
        width: 48px;
    }

    &::before {
        margin-right: 8px;
    }

    &::after {
        margin-left: 8px;
    }
`);

export const PoweredByYaspaLogo = styled('img', { label: 'PoweredByYaspaLogo' })<YaspaSegmentType>`
    margin: ${({ isSignup }): string => isSignup === true ? '24px 0 16px 0;' : '24px 0 0 0'};
    cursor: pointer;
`;
