import { action, computed, observable, makeObservable } from 'mobx';
import { Response200Type } from 'src/api_openapi/v2/bonus-engine/apiGetCampaign';
import { Common } from 'src/domains/common/Common';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { Resource } from 'src_common/common/mobx-utils/Resource';

export class TermsDrawerState {
    @observable public displayPage: boolean = false;
    @observable public id: string | null = null;
    private campaignsMap: AutoMap<string, Resource<Response200Type | null>>;
    public constructor(
        private readonly common: Common,
        private readonly customKeyboard: CustomKeyboardState
    ) {
        makeObservable(this);
        this.campaignsMap = new AutoMap(
            (id: string) =>
                new Resource(async (): Promise<Response200Type | null> => {
                    const data = await this.common.trpcClient.client.bonusEngineRouter.getCampaign.mutate({
                        id: id,
                    });
                    if (data.status === 200) {
                        return data.body;
                    }
                    return null;
                })
        );
    }

    @computed public get displayTermsPage(): boolean {
        return this.displayPage && this.terms !== null && this.terms !== '';
    }

    @action public showTermsPage = (id: string | null | undefined): void => {
        if (id === null || id === undefined) {
            return;
        }
        this.getCampaignTC(id);
        this.displayPage = true;

        if (this.customKeyboard.asideElement !== null) {
            this.customKeyboard.asideElement.scrollTo(0, 0);
        }
    };
    @action public getCampaignTC = (id: string): void => {
        this.id = id;
    };

    @computed public get terms(): string | null {
        if (this.id === null) {
            return null;
        }
        const newPage = this.campaignsMap.get(this.id).get();
        if (newPage.type === 'ready') {
            return newPage.value?.description ?? null;
        }

        if (newPage.type === 'loading') {
            return null;
        }
        return null;
    }

    @action public closePage = (): void => {
        this.displayPage = false;
    };
}
