import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon10 = observer('NumberIcon10', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='129'

            viewBox='0 0 181 129'

            width='181'

            xmlns='http://www.w3.org/2000/svg'

        >

            <g opacity='0.9'>

                <path d='M4.5 5.5084H60.9706V123.324H26.0126V33.4076H4.5V5.5084Z' fill='none'/>

                <path d='M123.666 4.5C140.697 4.5 153.806 9.65406 162.994 19.9622C172.182 30.2703 176.775 45.0602 176.775 64.3319C176.775 83.6036 172.182 98.4496 162.994 108.87C153.806 119.29 140.697 124.5 123.666 124.5C106.411 124.5 93.1901 119.346 84.0024 109.038C74.9268 98.6177 70.3889 83.7157 70.3889 64.3319C70.3889 45.0602 74.9268 30.2703 84.0024 19.9622C93.1901 9.65406 106.411 4.5 123.666 4.5ZM123.666 29.8781C117.392 29.8781 112.91 32.5112 110.221 37.7773C107.532 42.9314 106.187 51.7829 106.187 64.3319C106.187 76.881 107.532 85.7885 110.221 91.0546C112.91 96.3207 117.392 98.9538 123.666 98.9538C129.829 98.9538 134.198 96.3207 136.775 91.0546C139.465 85.7885 140.809 76.881 140.809 64.3319C140.809 51.7829 139.465 42.9314 136.775 37.7773C134.198 32.5112 129.829 29.8781 123.666 29.8781Z' fill='none'/>

                <path d='M4.5 5.5084H60.9706V123.324H26.0126V33.4076H4.5V5.5084Z' strokeWidth='8'/>

                <path d='M123.666 4.5C140.697 4.5 153.806 9.65406 162.994 19.9622C172.182 30.2703 176.775 45.0602 176.775 64.3319C176.775 83.6036 172.182 98.4496 162.994 108.87C153.806 119.29 140.697 124.5 123.666 124.5C106.411 124.5 93.1901 119.346 84.0024 109.038C74.9268 98.6177 70.3889 83.7157 70.3889 64.3319C70.3889 45.0602 74.9268 30.2703 84.0024 19.9622C93.1901 9.65406 106.411 4.5 123.666 4.5ZM123.666 29.8781C117.392 29.8781 112.91 32.5112 110.221 37.7773C107.532 42.9314 106.187 51.7829 106.187 64.3319C106.187 76.881 107.532 85.7885 110.221 91.0546C112.91 96.3207 117.392 98.9538 123.666 98.9538C129.829 98.9538 134.198 96.3207 136.775 91.0546C139.465 85.7885 140.809 76.881 140.809 64.3319C140.809 51.7829 139.465 42.9314 136.775 37.7773C134.198 32.5112 129.829 29.8781 123.666 29.8781Z' strokeWidth='8'/>

            </g>

        </SvgElement>

    );

});

