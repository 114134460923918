import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountLinks } from 'src/domains/players/webview/components/Account/common/accountLinks/AccountLinks';
import { AccountSummaryNew } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/AccountSummaryNew';
import { observer } from 'src/utils/mobx-react';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { useCommon, Common } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';

export interface AccountSummaryLinkType {
    name: React.ReactNode;
    to: RightHandSideViewType;
}

const defaultLinksFactory = (common: Common): AccountSummaryLinkType[] => {
    const { getTranslation, translateTokens } = LanguagesState.get(common);
    const { limitsAndResponsibleGambling, customerFundsProtection, depositLimitsType } =
        ConfigComponents.get(common).config;

    let linksTemp: AccountSummaryLinkType[] = [
        {
            name: (
                <I18n
                    langKey='account.links.top-up'
                    defaultText='Deposit'
                />
            ),
            to: { account: 'top-up', amount: null, view: null },
        },
        {
            name: (
                <I18n
                    langKey='account.links.withdraw'
                    defaultText='Withdraw'
                />
            ),
            to: { account: 'withdraw', view: null },
        },
        {
            name: (
                <I18n
                    langKey='account.links.bet-history'
                    defaultText='Bet History'
                />
            ),
            to: { account: 'bet-history' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.transaction-history'
                    defaultText='Transaction History'
                />
            ),
            to: { account: 'transaction-history' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.personal-details'
                    defaultText='Password &amp; Personal Details'
                />
            ),
            to: { account: 'personal-details' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.preferences-and-marketing'
                    defaultText='Settings &amp; Contact Preferences'
                />
            ),
            to: { account: 'preferences' },
        },
        {
            name:
                depositLimitsType === 'calendarType'
                    ? getTranslation('account.links-safer.limits', 'Limits & Safer Gambling')
                    : translateTokens(
                          getTranslation('account.links.limits', 'Limits & Responsible[separator]Gambling'),
                          (singleParam: LanguageTokenType) => {
                              if (singleParam.tag === 'separator') {
                                  return <br />;
                              }
                          }
                      ),
            to: { account: 'limits' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.customer-funds-protection'
                    defaultText='Customer Funds Protection'
                />
            ),
            to: { account: 'customer-funds-protection' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.faq'
                    defaultText="Help and FAQ's"
                />
            ),
            to: { account: 'static', static: 'help' },
        },
        {
            name: (
                <I18n
                    langKey='account.links.terms-and-conditions'
                    defaultText='Terms &amp; Conditions'
                />
            ),
            to: { account: 'static', static: 'terms-and-conditions' },
        },
    ];

    if (limitsAndResponsibleGambling === false) {
        linksTemp = linksTemp.filter((tab) => tab.to.account !== 'limits');
    }

    if (customerFundsProtection === false) {
        linksTemp = linksTemp.filter((tab) => tab.to.account !== 'customer-funds-protection');
    }

    return linksTemp;
};

const RenderLinks = observer('RenderLinks', (): JSX.Element => {
    const common = useCommon();
    const LinksComponent = AccountLinks;
    const links = defaultLinksFactory(common);

    return <LinksComponent links={links} />;
});

export const SummaryTab = observer('SummaryTab', () => {
    return (
        <>
            <AccountSummaryNew />
            <RenderLinks />
        </>
    );
});
