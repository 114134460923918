import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { CreditsState } from './Credits.state';
import { useCommon } from 'src/domains/common/Common';
import {
    CreditsCardContainer,
    CreditsTabContainer,
    CreditsTotalContainer,
    CreditsTotalValue,
    GiftIconStyled,
    RewardIconStyled,
} from './Credits.style';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { CreditsDrawerType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { CreditsBetSpinsCard } from './CreditsBetSpinsCard';
import { CreditsBonusBalanceCard } from './CreditsBonusBalanceCard';
import { useAppStateContext } from 'src/appState/AppState';

export interface CreditsPagePropsType {
    type: CreditsDrawerType;
}

export const Credits = observer('Credits', ({ type }: CreditsPagePropsType) => {
    const appState = useAppStateContext();
    const { getGameModelByLaunchGameId } = appState.appPlayersState.callbacks;
    const common = useCommon();
    const creditsState = CreditsState.get(common, type);
    const config = ConfigComponents.get(common);

    React.useEffect(() => {
        creditsState.bonusBalanceModel.bonusWinningsBalances.refresh();
        creditsState.bonusBalanceModel.customerBonuses.refresh();
    }, []);

    return (
        <CreditsTabContainer>
            <FiltersNewView
                select={creditsState.creditType}
                filters={creditsState.filters}
                skin={config.config.filtersVersion.credits}
            />
            <CreditsTotalContainer>
                <span>
                    <I18n
                        langKey='account.credits.total'
                        defaultText='Total'
                    />
                </span>
                <CreditsTotalValue>
                    {creditsState.creditType === 'free-bets' ? <GiftIconStyled /> : null}
                    {creditsState.creditType === 'free-spins' ? <RewardIconStyled /> : null}
                    {creditsState.getTotalValue}
                </CreditsTotalValue>
            </CreditsTotalContainer>
            <CreditsCardContainer>
                {creditsState.bonuses?.type === 'bet/spins'
                    ? creditsState.bonuses.data?.map((bonus) => (
                          <CreditsBetSpinsCard
                              key={bonus.bonusId}
                              bonus={bonus}
                              creditsState={creditsState}
                              getGameModelByLaunchGameId={getGameModelByLaunchGameId}
                          />
                      ))
                    : null}

                {creditsState.bonuses?.type === 'bonusBalance'
                    ? creditsState.bonuses.data?.map((bonus) => (
                          <CreditsBonusBalanceCard
                              bonus={bonus}
                              creditsState={creditsState}
                              key={bonus.bonusId}
                              getGameModelByLaunchGameId={getGameModelByLaunchGameId}
                          />
                      ))
                    : null}
            </CreditsCardContainer>
        </CreditsTabContainer>
    );
});
