import { action, computed, makeObservable, observable } from 'mobx';
import { DateTime } from 'src_common/utils/time/time';

interface MatchType {
    homeTeamName: string;
    awayTeamName: string;
    gameDate: string;
}

interface PlayMakerWidgetInstanceType {
    update: ({ homeTeamName, awayTeamName, gameDate }: MatchType) => void;
    changeSettings: (
        settings: Record<string, string | boolean | string[] | Record<string, string | boolean | string[]>>
    ) => void;
    remove: () => void;
    _loading: boolean;
    _matchId: number | null;
    _data: MatchType | null;
}

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        playMaker: (
            command: 'init' | 'addGoalWidget' | 'addSpiderWidget' | 'addXWidget',
            options: Record<string, string | boolean | string[] | Record<string, string | boolean | string[]>>
        ) => PlayMakerWidgetInstanceType;
    }
}

export class MatchStatsIframeState {
    @observable public isOpen: boolean;
    @observable private homeTeamName: string;
    @observable private awayTeamName: string;
    @observable private gameDate: string;
    @observable private isMobile: boolean;
    @observable private isPremierLeague: boolean;

    private isInit: boolean = false;
    private goalWidgetRef: PlayMakerWidgetInstanceType | null = null;
    private spiderWidgetRef: PlayMakerWidgetInstanceType | null = null;
    private xWidgetRef: PlayMakerWidgetInstanceType | null = null;

    public constructor(
        isOpen: boolean,
        homeTeamName: string,
        awayTeamName: string,
        gameDate: string,
        isMobile: boolean,
        isPremierLeague: boolean
    ) {
        makeObservable(this);

        this.homeTeamName = homeTeamName;
        this.awayTeamName = awayTeamName;
        this.gameDate = gameDate;
        this.isMobile = isMobile;
        this.isOpen = isOpen;
        this.isPremierLeague = isPremierLeague;
    }

    @computed public get formattedGameDate(): string {
        return (DateTime.from(this.gameDate) ?? DateTime.current()).format('YYYY-MM-DD');
    }

    @action public toggle = (): void => {
        this.isOpen = !this.isOpen;
    };

    private initPlayMaker = (): void => {
        if (this.isInit === false) {
            window.playMaker('init', { logLevel: 'log', debug: true });
        }

        this.isInit = true;
    };

    public setGoalWidgetRef = (element: HTMLDivElement | null): void => {
        if (this.isMobile) return;

        if (element === null) {
            this.goalWidgetRef?.remove();
        } else {
            this.initPlayMaker();
            try {
                this.goalWidgetRef = window.playMaker('addGoalWidget', {
                    awayTeamName: this.awayTeamName,
                    homeTeamName: this.homeTeamName,
                    gameDate: this.formattedGameDate,
                    selectorId: element.id,
                    settings: [
                        'xG',
                        'xGC',
                        'averageScored',
                        'averageConceded',
                        'averageScoredHomeAndAway',
                        'topScorersXG',
                    ],
                    theme: 'dark',
                    locale: 'en',
                });
            } catch (error) {
                console.error('oops x widget:', error);
            }
        }
    };

    public setSpiderWidgetRef = (element: HTMLDivElement | null): void => {
        if (this.isMobile) return;

        // Temp bug fix, while we wait on Playmaker to add mappings
        const shouldUseMapping =
            this.awayTeamName === 'Manchester City' &&
            this.homeTeamName === 'Paris Saint Germain' &&
            this.formattedGameDate === '2025-01-22';

        const teamMappings: Record<string, string> = {
            'Paris Saint Germain': 'PSG',
        };

        if (element === null) {
            this.spiderWidgetRef?.remove();
        } else {
            this.initPlayMaker();
            try {
                this.spiderWidgetRef = window.playMaker('addSpiderWidget', {
                    awayTeamName: this.awayTeamName,
                    homeTeamName: shouldUseMapping
                        ? teamMappings[this.homeTeamName] ?? this.homeTeamName
                        : this.homeTeamName,
                    gameDate: this.formattedGameDate,
                    selectorId: element.id,
                    settings: {
                        displayMode: 'entireSeason',
                        tooltipOpen: false,
                        theme: 'dark',
                        locale: 'en',
                    },
                });
            } catch (error) {
                console.error('oops x widget:', error);
            }
        }
    };

    public setXWidgetRef = (element: HTMLDivElement | null): void => {
        const goalMetrics = [
            'xG',
            'xGC',
            'averageScored',
            'averageConceded',
            'averageScoredHomeAndAway',
            'topScorersXG',
        ];

        const pointMetrics = ['xp', 'points', 'xpPointsDiff', 'expectedTablePosition', 'tablePosition'];

        // Temp bug fix, while we wait on Playmaker to add mappings
        const shouldUseMapping =
            this.awayTeamName === 'Manchester City' &&
            this.homeTeamName === 'Paris Saint Germain' &&
            this.formattedGameDate === '2025-01-22';

        const teamMappings: Record<string, string> = {
            'Paris Saint Germain': 'PSG',
        };

        if (element === null) {
            this.xWidgetRef?.remove();
        } else {
            this.initPlayMaker();
            try {
                this.xWidgetRef = window.playMaker('addXWidget', {
                    awayTeamName: this.awayTeamName,
                    homeTeamName: shouldUseMapping
                        ? teamMappings[this.homeTeamName] ?? this.homeTeamName
                        : this.homeTeamName,
                    gameDate: this.formattedGameDate,
                    selectorId: element.id,
                    settings: {
                        displayMode: 'entireSeason',
                        metrics: this.isMobile || this.isPremierLeague === false ? goalMetrics : pointMetrics,
                        theme: 'dark',
                        locale: 'en',
                    },
                });
            } catch (error) {
                console.error('oops x widget:', error);
            }
        }
    };
}
