import React from 'react';
import { AppCasinoState, CasinoGameModelExt, CollectionType } from 'src/domains/casino/state/AppCasino.state';
import { GamesSliderCasino } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesSlider';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
    gameModels: Array<CasinoGameModelExt>;
    title: string | JSX.Element;
    withoutLikeAndInfo?: boolean;
    onViewAllClick?: () => void;
}

export const CollectionSlider = observer('CollectionSlider', (props: PropsType) => {
    const { appCasinoState, collection, gameModels, title, withoutLikeAndInfo, onViewAllClick } = props;

    if (appCasinoState.configState.commonConfig.casinoPublishedCollections.includes(collection) === false) {
        return null;
    }

    return (
        <GamesSliderCasino
            appCasinoState={appCasinoState}
            title={title}
            gameModels={gameModels}
            onViewAllClick={onViewAllClick}
            withoutLikeAndInfo={withoutLikeAndInfo}
        />
    );
});
