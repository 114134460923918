import { action, computed, observable, makeObservable } from 'mobx';
import { assertNever } from 'src_common/common/assertNever';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { RacingFilterGroupState } from 'src/domains/sportsbook/webview/components/racingFilters/RacingFilterGroupState';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventsCollectionState } from 'src/domains/sportsbook/shared/Types';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { Logger } from 'src/domains/common/Logger';

const getEventsCollection = (
    eventsCollection: EventsCollectionState,
    models: ModelsState,
    sport: RaceSportType
): EventsCollectionList => {
    switch (sport) {
        case 'horseracing': {
            const races = eventsCollection.getRaces('horseracing');

            return races.filterBySportAndEvent((_sport, event): boolean => {
                const eventModel = models.getEvent(event.id);
                if (eventModel === null) {
                    return false;
                }
                return eventModel.tagsFootballSpecial !== 'yes';
            });
        }
        case 'greyhoundracing': {
            return eventsCollection.getRaces('greyhoundracing');
        }
        default: {
            return assertNever('RacesSlider -> eventsCollection', sport);
        }
    }
};

class RacesSliderData {
    public readonly eventsCollection: EventsCollectionList;
    public readonly eventsFilterState: RacingFilterGroupState;

    public constructor(
        language: LanguagesState,
        eventsCollection: EventsCollectionState,
        private readonly models: ModelsState,
        private readonly selectListAndResetOffset: (listType: SelectedListType) => void,
        private readonly sport: RaceSportType,
        private readonly getSelectedList: () => SelectedListType
    ) {
        makeObservable(this);
        this.eventsCollection = getEventsCollection(eventsCollection, models, sport);
        this.eventsFilterState = new RacingFilterGroupState(
            (): EventsCollectionList => this.eventsCollection,
            this.selectListAndResetOffset,
            language
        );
    }

    @computed public get listKey(): string {
        const filterId = this.eventsFilterState.todayFilterStrategy.getActiveFilterId() ?? 0;
        return `${this.sport}-${filterId.toString()}`;
    }

    @computed private get eventIds(): Array<number> {
        return this.eventsCollection.ids;
    }

    @computed public get eventsForView(): Array<EventModel> | 'loading' {
        const out = [];

        for (const id of this.eventIds) {
            const event = this.models.getEvent(id);

            if (event === null) {
                Logger.captureError(`RaceCard: Could not find an event with id: ${id}`, 'internal', { id });
                continue;
            } else {
                out.push(event);
            }
        }

        const outToReturn = out
            .filter((x) => x.display && !x.timeSettingsStarted)
            .filter((eventModel) =>
                this.eventsFilterState.isEventShouldBeDisplayed(this.getSelectedList(), eventModel)
            );

        if (outToReturn.length > 2) {
            return outToReturn;
        }

        return outToReturn;
    }
}

type SelectedListType = 'all' | 'today' | 'tomorrow';
export type RaceSportType = 'horseracing' | 'greyhoundracing';

export type RacesSliderType = 'homePageWidget' | 'horseracing' | 'greyhoundracing';

export class RacesSliderState {
    private readonly data: MobxMapAutoNew<RaceSportType, RacesSliderData>;
    @observable public selectedList: SelectedListType = 'all';
    @observable public isOpen: boolean = true;
    @observable public nextOffWidgetSport: RaceSportType = 'horseracing';

    public constructor(
        language: LanguagesState,
        eventsCollection: EventsCollectionState,
        models: ModelsState,
        private readonly initialSport: RacesSliderType
    ) {
        makeObservable(this);
        this.data = new MobxMapAutoNew(
            (sport: RaceSportType): RacesSliderData =>
                new RacesSliderData(
                    language,
                    eventsCollection,
                    models,
                    this.selectListAndResetOffset,
                    sport,
                    () => this.selectedList
                )
        );
    }

    @action public setHorseRacingNextOff = (): void => {
        this.data.get('horseracing').eventsFilterState.todayFilterStrategy.setActiveFilterId(-1);
        this.nextOffWidgetSport = 'horseracing';
        this.selectListAndResetOffset('today');
    };

    @action public setGreyhoundRacingNextOff = (): void => {
        this.data.get('greyhoundracing').eventsFilterState.todayFilterStrategy.setActiveFilterId(-1);
        this.nextOffWidgetSport = 'greyhoundracing';
        this.selectListAndResetOffset('today');
    };

    public isSelected = (sport: RaceSportType): boolean => {
        return this.nextOffWidgetSport === sport;
    };

    @action public onToggle = (): void => {
        this.isOpen = !this.isOpen;
    };

    @computed public get eventsCollection(): RacesSliderData {
        const sport = this.racingSport;
        return this.data.get(sport);
    }

    @action public selectListAndResetOffset = (listType: SelectedListType): void => {
        this.selectedList = listType;

        if (this.eventsCollection.eventsForView.length === 0) {
            this.selectedList = 'all';
        }
    };

    @computed public get racingSport(): RaceSportType {
        if (this.initialSport === 'homePageWidget') {
            return this.nextOffWidgetSport;
        }

        return this.initialSport;
    }
}
