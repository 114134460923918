import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    Selection,
    SelectItem,
    SelectionRabListItem,
    SelectionRabListItemName,
    SelectionRabListItemCutName,
} from './SelectionRabItem.style';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface ListItemPropsType {
    rabId: BetslipRabId;
    isSelect: boolean;
    marketName: string;
    marketTemplateType?: string;
    name: string;
    viewName?: string;
    participantName?: string | null | undefined;
    displayName?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
}

export const ListItem = observer('ListItem', (props: ListItemPropsType) => {
    const {
        name,
        isSelect,
        participantName,
        marketTemplateType,
        viewName,
        displayName,
        borderLeft,
        borderRight,
        marketName,
        rabId,
    } = props;

    const onClick = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        const rabEvent = rabId.getModel();

        if (isSelect === true) {
            rabEvent.removeSelection(marketName, name);
        } else {
            rabEvent.addSelection(marketName, name);
        }
    };

    return (
        <SelectionRabListItem
            onClick={onClick}
            isSelect={isSelect}
            borderLeft={borderLeft}
            borderRight={borderRight}
        >
            {displayName === true ? (
                <SelectionRabListItemName
                    title={name}
                    marketTemplateType={marketTemplateType}
                >
                    {participantName !== null && participantName !== undefined ? (
                        <SelectionRabListItemCutName>{participantName}</SelectionRabListItemCutName>
                    ) : null}
                    {viewName === undefined ? name : viewName}
                </SelectionRabListItemName>
            ) : null}
            <Selection>
                <SelectItem isSelect={isSelect} />
            </Selection>
        </SelectionRabListItem>
    );
});
