import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Slider } from 'src/domains/casino/webview/CasinoGames/home-games/slider/Slider';
import { GamesWrapper } from 'src/domains/casino/webview/CasinoGames/home-games/games-home-view/GamesHome.style';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { SingleGameExt } from 'src/domains/casino/webview/CasinoGames/single-game-ext/SingleGameExt';
import { useCommon } from 'src/domains/common/Common';

interface GameWrapperPropsType {
    appCasinoState: AppCasinoState;
    gameModel: CasinoGameModelExt;
    withoutLikeAndInfo?: boolean;
}

const GameWrapper = observer('GameWrapper', (props: GameWrapperPropsType) => {
    const { appCasinoState, gameModel } = props;
    const showLikeAndInfo = props.withoutLikeAndInfo === true ? false : undefined;

    return (
        <SingleGameExt
            appCasinoState={appCasinoState}
            key={gameModel.id}
            gameModel={gameModel}
            wrapperType='slider'
            showLikeAndInfo={showLikeAndInfo}
        />
    );
});

interface GamesSliderPropsType {
    appCasinoState: AppCasinoState;
    title: string | JSX.Element;
    gameModels: Array<CasinoGameModelExt>;
    withoutViewAll?: boolean;
    withoutLikeAndInfo?: boolean;
    onViewAllClick?: () => void;
}

export const GamesSliderCasino = observer('GamesSliderCasino', (props: GamesSliderPropsType) => {
    const { appCasinoState, gameModels, title, withoutViewAll, withoutLikeAndInfo, onViewAllClick } = props;

    if (gameModels.length === 0) {
        return null;
    }

    return (
        <Slider
            appCasinoState={appCasinoState}
            title={title}
            onViewAllClick={onViewAllClick}
            withoutViewAll={withoutViewAll}
        >
            <GamesWrapper>
                {gameModels.map((gameModel) => {
                    return (
                        <GameWrapper
                            appCasinoState={appCasinoState}
                            key={gameModel.id}
                            gameModel={gameModel}
                            withoutLikeAndInfo={withoutLikeAndInfo}
                        />
                    );
                })}
            </GamesWrapper>
        </Slider>
    );
});

interface GamesSliderMultiCollectionPropsType {
    appCasinoState: AppCasinoState;
    title: string | JSX.Element;
    gameModels: Array<CasinoGameModelExt>;
    withoutViewAll?: boolean;
    withoutLikeAndInfo?: boolean;
    onViewAllClick?: () => void;
}

export const GamesSliderMultiCollection = observer(
    'GamesSliderMultiCollection',
    (props: GamesSliderMultiCollectionPropsType) => {
        const { appCasinoState, gameModels, title, withoutViewAll, withoutLikeAndInfo, onViewAllClick } = props;
        const common = useCommon();
        const isUserAuthorized = common.session.isAuthorized;

        if (gameModels.length === 0 || isUserAuthorized === false) {
            return null;
        }

        return (
            <Slider
                appCasinoState={appCasinoState}
                title={title}
                onViewAllClick={onViewAllClick}
                withoutViewAll={withoutViewAll}
            >
                <GamesWrapper>
                    {gameModels.map((gameModel) => (
                        <GameWrapper
                            appCasinoState={appCasinoState}
                            key={gameModel.id}
                            gameModel={gameModel}
                            withoutLikeAndInfo={withoutLikeAndInfo}
                        />
                    ))}
                </GamesWrapper>
            </Slider>
        );
    }
);
