import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon1 = observer('NumberIcon1', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 67 128'

            width='67'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M4.5 4H62.0178V124H26.4116V32.4165H4.5V4Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

