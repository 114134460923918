import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    InfoContentWrapper,
    CloseButton,
    StyledCloseIcon,
    InfoContentTitle,
    InfoContent,
    InfoWrapper,
} from './TermsAndConditions.style';
import { ArrowBackIcon } from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader.style';

interface TermsPagePropsType {
    title: string;
    content: string;
    hasArrow?: boolean;
    closePage: () => void;
}

export const TermsPage = observer('TermsPage', ({ title, content, hasArrow, closePage }: TermsPagePropsType) => {
    return (
        <InfoWrapper signUpCheckboxTC={false}>
            <InfoContentWrapper>
                {/* TODO: data-test='title-header' same as in Account/accountDrawerHeader/AccountDrawerHeader.tsx - should be changed to be more specific*/}
                {hasArrow === true ? (
                    <InfoContentTitle
                        data-test='title-header'
                        hasArrow
                    >
                        <ArrowBackIcon
                            position='left'
                            onClick={closePage}
                        />

                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </InfoContentTitle>
                ) : (
                    <InfoContentTitle data-test='title-header'>
                        <div dangerouslySetInnerHTML={{ __html: title }} />

                        <CloseButton
                            type='button'
                            onClick={closePage}
                        >
                            <StyledCloseIcon />
                        </CloseButton>
                    </InfoContentTitle>
                )}
                <InfoContent dangerouslySetInnerHTML={{ __html: content }} />
            </InfoContentWrapper>
        </InfoWrapper>
    );
});
