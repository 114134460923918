import styled from '@emotion/styled';

import * as React from 'react';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface PropsType {

    className?: string

}



export const NumberIcon5 = observer('NumberIcon5', ({ className }: PropsType) => {

    return (

        <SvgElement

            className={className}

            height='128'

            viewBox='0 0 107 128'

            width='107'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='none'

                d='M56.2042 46.7606C65.5563 46.7606 73.7253 48.2817 80.7113 51.3239C87.8099 54.2535 93.2746 58.4225 97.1056 63.831C100.937 69.2394 102.852 75.493 102.852 82.5916C102.852 90.8169 100.824 98.0845 96.7676 104.394C92.7113 110.592 86.9648 115.437 79.5282 118.93C72.0915 122.31 63.4155 124 53.5 124C45.3873 124 36.993 122.648 28.3169 119.944C19.6408 117.127 12.0352 113.408 5.5 108.789L18.3451 84.4507C23.3028 88.6197 28.4296 91.8873 33.7253 94.2535C39.0211 96.6197 44.3169 97.8028 49.6127 97.8028C54.5704 97.8028 58.3451 96.7324 60.9366 94.5915C63.6408 92.338 64.993 89.1831 64.993 85.1268C64.993 77.0141 59.6972 72.9577 49.1056 72.9577C36.5986 72.9577 25.6127 73.0704 16.1479 73.2958V4H97.4436V31.3803H48.2606V46.7606H56.2042Z'

                opacity='0.9'

                strokeWidth='8'

            />

        </SvgElement>

    );

});

