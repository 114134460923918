import { observable, makeObservable } from 'mobx';
import { AppState } from 'src/appState/AppState';

export class YouDecideMobileState {
    public readonly appState: AppState;
    @observable public rabListIsOpened: boolean = false;
    @observable public isConfirmationOpened: boolean = false;

    public constructor(appState: AppState) {
        makeObservable(this);
        this.appState = appState;
    }

    public handleToggleRabSection = (): void => {
        this.rabListIsOpened = !this.rabListIsOpened;
    };

    public handleCloseRabSection = (): void => {
        this.rabListIsOpened = false;
        this.isConfirmationOpened = false;
    };

    public onAddToBetslipInner = (): void => {
        this.appState.appSportsBookState.rab.addToBetslip();
        this.isConfirmationOpened = true;

        setTimeout(() => {
            this.isConfirmationOpened = false;
            this.rabListIsOpened = false;
        }, 3000);
    };
}
