import { action, computed, makeObservable, observable } from 'mobx';
import { ReferralState } from 'src/domains/sportsbook/betting/betSlipState/ReferralState';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import { LegsState } from 'src/domains/sportsbook/betting/state/betSlipState/LegsState';
import { CombinationState } from 'src/domains/sportsbook/betting/state/betSlipState/CombinationsState';
import { BasicBetSlipState } from 'src/domains/sportsbook/betting/betSlipState/BasicBetSlipState';
import {
    NewTradingPostBetData,
    SuccessPlaceBetResponseType,
} from 'src/domains/sportsbook/betting/betting/postPlaceBetTypes';
import {
    InternalPlaceBetErrorsMessages,
    NewPlaceBetRequestType,
    ParsedPlaceBetType,
    PlaceBetBetsType,
    PlaceBetRabBetType,
    postPlaceBet,
    postPlaceBetNewTrading,
    preparePlaceBetRequestBody,
    RabBetType,
} from 'src/domains/sportsbook/betting/betting/postPlaceBet';
import { CastBetsState } from 'src/domains/sportsbook/betting/state/betSlipState/CastBetsState';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

import { CastBetType, LegType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipTypes';
import { CustomerFreeBetsType, ResourceCustomer } from 'src/domains/players/shared/Types';
import { Amount } from 'src_common/common/amount/Amount';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';

import { Logger } from 'src/domains/common/Logger';
import { ArrayBetsType, ErrorMsgType, parsePlaceBetErrors, parsePlaceBetLegs } from './BetSlipSummaryState.utils';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import * as uuid from 'uuid';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { Common } from 'src/domains/common/Common';
import { SymplifyState } from 'src/domains/layouts/state/symplify/SymplifyState';
import { SelectedBonusType } from 'src/domains/sportsbook/betting/state/BetSlipState';

interface BetDetailsLegsType {
    selectionName: string;
    marketName: string;
    eventName: string;
    priceBet: string | null;
    betId: number;
    stake: string;
    potentialReturns: string | null;
}

interface BetReceiptType {
    totalStake: Amount;
    totalPotentialReturns: Amount;
    isFreeBet: boolean;
    details?: {
        legs: Array<BetDetailsLegsType>;
    };
    totalBonusEngineFreeBetsAmount: Amount | null;
    totalCashStake: Amount | null;
}

type PlaceBetStatusType = 'SUCCESS' | 'IN_PROGRESS' | 'ERROR';

interface FreeBetNewType {
    id: number;
    amount: string;
}

interface FreeBetOldType {
    id: number;
    amount: number | string;
}

export class BetSlipSummaryState {
    private readonly possibleBetsRequestState: PossibleBetsRequestState;
    private readonly legsState: LegsState;
    private readonly combinationState: CombinationState;
    private readonly rabState: RabState | null;
    private readonly referralState: ReferralState;
    private readonly basicBetSlipState: BasicBetSlipState;
    private readonly castBetsState: CastBetsState;
    private readonly getFreeBetsAmount: () => Amount | null;
    private readonly getLegsIds: () => Array<BetslipSingleId>;
    private readonly onCleanAll: () => void;
    private readonly lifeSpanState: LifeSpanState;
    private readonly localStorageState: LocalStorageState;
    private readonly configComponents: ConfigComponents;

    public readonly onGoogleTagManagerBetPlacedTag: EventEmmiter<Array<ArrayBetsType>>;

    @observable private betReceiptSummary: BetReceiptType | null = null;
    // todo use Resource
    @observable public placeBetStatus: PlaceBetStatusType | null = null;
    @observable private responseErrorMsgInner: Array<ErrorMsgType> = [];

    @observable.ref public betTooHighValue: Amount | null = null;

    public constructor(
        possibleBetsRequestState: PossibleBetsRequestState,
        legsState: LegsState,
        combinationState: CombinationState,
        rabState: RabState | null,
        referralState: ReferralState,
        basicBetSlipState: BasicBetSlipState,
        castBetsState: CastBetsState,
        getFreeBetsAmount: () => Amount | null,
        getLegsIds: () => Array<BetslipSingleId>,
        onCleanAll: () => void,
        private readonly sdk: SdkCustomer,
        private readonly onRedirectToBetslip: () => void,
        private readonly freeBetsData: () => ResourceCustomer<CustomerFreeBetsType | null>,
        lifeSpanState: LifeSpanState,
        private readonly getChannel: () => ChannelType,
        private readonly common: Common,
        private readonly selectedBonuses: Map<string, Array<SelectedBonusType>>
    ) {
        makeObservable(this);
        this.possibleBetsRequestState = possibleBetsRequestState;
        this.legsState = legsState;
        this.combinationState = combinationState;
        this.referralState = referralState;
        this.basicBetSlipState = basicBetSlipState;
        this.castBetsState = castBetsState;
        this.getFreeBetsAmount = getFreeBetsAmount;
        this.getLegsIds = getLegsIds;
        this.onCleanAll = onCleanAll;
        this.rabState = rabState;
        this.lifeSpanState = lifeSpanState;

        this.onGoogleTagManagerBetPlacedTag = new EventEmmiter();
        this.localStorageState = LocalStorageState.get(this.common);
        this.configComponents = ConfigComponents.get(this.common);
    }

    @computed private get freeBetsAmount(): Amount | null {
        return this.getFreeBetsAmount();
    }

    private prepareRabBets(fromFreeBets: boolean): Array<PlaceBetRabBetType> {
        const resultPrepareRabBets: Array<RabBetType> = [];
        const rabBets = this.rabState === null ? [] : this.rabState.getBets(this.payoutRab);
        const possibleRabs = this.possibleBetsRequestState.coreRabPossibleBetsResponse;

        for (const rab of rabBets) {
            const possibleRab = possibleRabs.find((elem) => elem.platformId === rab.platformId);
            if (fromFreeBets) {
                resultPrepareRabBets.push({
                    ...rab,
                    freebetCredits: possibleRab?.freebetCredits ?? [],
                    freebetRemarks: possibleRab?.freebetRemarks ?? [],
                });
            } else {
                resultPrepareRabBets.push(rab);
            }
        }

        // TODO: Adjust mapping when RAB will be adjusted to return new Amount value
        return resultPrepareRabBets.map(({ stakePerLine, ...el }) => ({
            ...el,
            stakePerLine: this.configComponents.precision.newFromOld(stakePerLine ?? 0).value,
        }));
    }

    private convertFreeBetsToNew = (freebetCredits: FreeBetOldType): FreeBetNewType => {
        return {
            id: freebetCredits.id,
            amount:
                typeof freebetCredits.amount === 'number'
                    ? $appState.appLayoutsState.configComponents.precision.newFromOld(freebetCredits.amount).value
                    : freebetCredits.amount,
        };
    };

    private convertFreeBetsToOld = (freebetCredits: FreeBetNewType): FreeBetOldType => {
        return {
            id: freebetCredits.id,
            amount:
                $appState.appLayoutsState.configComponents.precision.valueOldFormat(new Amount(freebetCredits.amount)) /
                100, // TODO: remove this division by 100 after new trading is implemented everywhere
        };
    };

    private getFreeBets = async (bets: NewTradingPostBetData[]): Promise<NewTradingPostBetData[]> => {
        const betsWithNewFormat = bets.map((bet) => {
            return {
                ...bet,
                //TODO - remove from this NewTradingPostBetData type this correlationId key
                correlationId: bet.correlationId ?? uuid.v4(),
                freebetCredits: bet.freebetCredits.map(this.convertFreeBetsToNew),
            };
        });

        const response = await $appState.common.trpcClient.client.betting.getFreeBets.mutate({
            bets: betsWithNewFormat,
        });

        if (response.type === 'error') {
            console.error('getFreeBets', response);
            throw Error('getFreeBets -> Problem fetching free bet information');
        }

        return betsWithNewFormat.map((bet): NewTradingPostBetData => {
            const correlationIdfree = response.data[bet.correlationId];

            if (correlationIdfree === undefined) {
                throw Error(`missing free bets for ${JSON.stringify(bet)}`);
            }

            const betNew: NewTradingPostBetData = {
                ...bet,
                freebetCredits: correlationIdfree.map(this.convertFreeBetsToOld),
            };
            return betNew;
        });
    };

    private getFreeBetsRab = async (bets: PlaceBetRabBetType[]): Promise<PlaceBetRabBetType[]> => {
        const betsWithNewFormat = bets;

        const response = await $appState.common.trpcClient.client.betting.getFreeBets.mutate({
            bets: betsWithNewFormat,
        });

        if (response.type === 'error') {
            throw Error('getFreeBetsRab -> Problem fetching free bet information');
        }

        return betsWithNewFormat.map((bet): PlaceBetRabBetType => {
            const correlationIdfree = response.data[bet.correlationId];

            if (correlationIdfree === undefined) {
                throw Error(`missing free bets for ${JSON.stringify(bet)}`);
            }

            const betNew: PlaceBetRabBetType = {
                ...bet,
                freebetCredits: correlationIdfree,
            };

            return betNew;
        });
    };

    private async dataForPlaceBetNewTrading(
        fromFreeBets: boolean,
        selectedBonuses?: Map<string, Array<SelectedBonusType>>
    ): Promise<NewPlaceBetRequestType | null> {
        if (this.isDisabledBettingButton === false) {
            const { accountAuthenticated } = this.basicBetSlipState.userData;

            if (
                this.basicBetSlipState.placeBetStatus === 'IN_PROGRESS' ||
                this.isLegsChangedWarning ||
                accountAuthenticated === false ||
                (this.possibleBetsRequestState.castBets.length === 0 &&
                    this.possibleBetsRequestState.coreRabPossibleBetsResponse.length === 0) ||
                (this.basicBetSlipState.betSlipPlayableBalanceAmounts !== null && fromFreeBets === false) ||
                (fromFreeBets === true && this.castBetsState.isFreeBetExceedsAmount) ||
                (this.referralState.referralData !== null && this.referralState.status === 'request')
            ) {
                let warnMsg = 'onPlaceBet error';
                if (this.basicBetSlipState.placeBetStatus === 'IN_PROGRESS') {
                    warnMsg = 'onPlaceBet error: placeBetStatus in progress';
                } else if (this.isLegsChangedWarning) {
                    warnMsg = 'onPlaceBet error: isLegsChangedWarning';
                } else if (accountAuthenticated === false) {
                    warnMsg = 'onPlaceBet error: accountAuthenticated failed';
                } else if (
                    this.possibleBetsRequestState.castBets.length === 0 &&
                    this.possibleBetsRequestState.coreRabPossibleBetsResponse.length === 0
                ) {
                    warnMsg = 'onPlaceBet error: castBets count is 0 and rabPossibleBets is 0';
                } else if (this.basicBetSlipState.betSlipPlayableBalanceAmounts !== null && fromFreeBets === false) {
                    warnMsg = 'onPlaceBet error: fromFreeBets is false';
                } else if (fromFreeBets === true && this.castBetsState.isFreeBetExceedsAmount) {
                    warnMsg = 'onPlaceBet error: is from free bets and is free bet exceeds amount';
                } else if (this.referralState.referralData !== null && this.referralState.status === 'request') {
                    warnMsg = 'onPlaceBet error: referralState status is request';
                }
                Logger.captureError('onPlaceBet error', 'Sportsbook', {
                    'Betslip info': {
                        basicBetSlipState: this.basicBetSlipState.placeBetStatus,
                        isLegsChangedWarning: this.isLegsChangedWarning,
                        possibleBetsRequestState: JSON.stringify(this.possibleBetsRequestState),
                        betSlipPlayableBalanceAmounts: JSON.stringify(
                            this.basicBetSlipState.betSlipPlayableBalanceAmounts
                        ),
                        isFreeBetExceedsAmount: this.castBetsState.isFreeBetExceedsAmount,
                        referralData: JSON.stringify(this.referralState.referralData),
                        referralStateStatus: this.referralState.status,
                    },
                });
                console.error(warnMsg);
                return null;
            }

            //New version without possible bet in frontend api - trading refactor part

            const bets = this.convertSimpleToBets(
                this.legsState.forPlaceBet,
                this.combinationState.castCombinationsPossibleBetsResponse
            );

            const data: NewPlaceBetRequestType = {
                channel: this.getChannel(),
                rabBets: fromFreeBets
                    ? await this.getFreeBetsRab(this.prepareRabBets(fromFreeBets))
                    : this.prepareRabBets(fromFreeBets),
                bets: fromFreeBets ? await this.getFreeBets(bets) : bets,
                possibleBetSuccess: this.possibleBetsRequestState.isSuccess,
            };

            if (selectedBonuses !== undefined) {
                const betsWithBonuses = data.bets.map((bet) => {
                    const betBonusesForSelection = selectedBonuses.get(bet.id);

                    if (betBonusesForSelection !== undefined) {
                        const stakePerLineAmount = this.configComponents.precision.newFromAnything(bet.stakePerLine);

                        const decoratedStake =
                            bet.eachWay === true ? stakePerLineAmount.multiply(2) : stakePerLineAmount;

                        const freebetCredits = betBonusesForSelection.reduce<
                            Array<{ id: number; newFreeBetId: string; amount: string }>
                        >((acc, bonus, idx) => {
                            const remainingStake = decoratedStake.sub(
                                acc.reduce((sum, credit) => sum.add(new Amount(credit.amount)), new Amount('0'))
                            );

                            if (remainingStake.isGreaterThanZero()) {
                                const bonusAmount = bonus.currentAmount;
                                const amountToUse = remainingStake.isGreaterThan(bonusAmount)
                                    ? bonusAmount
                                    : remainingStake;

                                acc.push({
                                    id: idx,
                                    newFreeBetId: bonus.id,
                                    amount: amountToUse.value,
                                });
                            }

                            return acc;
                        }, []);

                        return {
                            ...bet,
                            freeBets: [],
                            freebetCredits,
                        };
                    }

                    const betBonusesForCombination = selectedBonuses.get(bet.type);

                    if (betBonusesForCombination !== undefined) {
                        const stakePerLineAmount = this.configComponents.precision.newFromAnything(bet.stakePerLine);

                        const decoratedStake =
                            bet.eachWay === true ? stakePerLineAmount.multiply(2) : stakePerLineAmount;

                        return {
                            ...bet,
                            freeBets: [],
                            freebetCredits: betBonusesForCombination.reduce<
                                Array<{ id: number; newFreeBetId: string; amount: string }>
                            >((acc, bonus, idx) => {
                                const remainingStake = decoratedStake.sub(
                                    acc.reduce((sum, credit) => sum.add(new Amount(credit.amount)), new Amount('0'))
                                );

                                if (remainingStake.isGreaterThanZero()) {
                                    const bonusAmount = bonus.currentAmount;
                                    const amountToUse = remainingStake.isGreaterThan(bonusAmount)
                                        ? bonusAmount
                                        : remainingStake;

                                    acc.push({
                                        id: idx,
                                        newFreeBetId: bonus.id,
                                        amount: amountToUse.value,
                                    });
                                }

                                return acc;
                            }, []),
                        };
                    }

                    return { ...bet, freeBets: [] };
                });

                data.bets = betsWithBonuses;

                if (data.rabBets !== null && data.rabBets.length > 0) {
                    const rabBetsWithBonuses = data.rabBets.map((bet) => {
                        const betBonusesForSelection = selectedBonuses.get(bet.correlationId);

                        if (betBonusesForSelection !== undefined) {
                            const freebetCredits = betBonusesForSelection.reduce<
                                Array<{ id: number; newFreeBetId: string; amount: string }>
                            >((acc, bonus, idx) => {
                                const remainingStake = this.configComponents.precision
                                    .newFromAnything(bet.stakePerLine)
                                    .sub(
                                        acc.reduce((sum, credit) => sum.add(new Amount(credit.amount)), new Amount('0'))
                                    );

                                if (remainingStake.isGreaterThanZero()) {
                                    const bonusAmount = bonus.currentAmount;
                                    const amountToUse = remainingStake.isGreaterThan(bonusAmount)
                                        ? bonusAmount
                                        : remainingStake;

                                    acc.push({
                                        id: idx,
                                        newFreeBetId: bonus.id,
                                        amount: amountToUse.value,
                                    });
                                }

                                return acc;
                            }, []);

                            return {
                                ...bet,
                                freeBets: undefined,
                                freebetCredits,
                            };
                        }
                        return bet;
                    });

                    data.rabBets = rabBetsWithBonuses;
                }
            }

            return data;
        }
        return null;
    }

    @action public handleFreeBetsPlace = async (fromFreeBets: boolean): Promise<void> => {
        const data = await this.dataForPlaceBetNewTrading(true);

        if (data !== null) {
            console.info('Place bet with new data free');
            this.handlePlaceBetRequest(fromFreeBets, data).catch((error) => {
                Logger.captureError('handlePlaceBetRequest error', 'Sportsbook', {
                    'Betslip info': {
                        data: JSON.stringify(data),
                    },
                });
                console.error('handleFreeBetsPlace error', error);
            });
            this.basicBetSlipState.onHideKeyboard();
        }
    };

    @action public onPlaceBet = async (
        fromFreeBets: boolean,
        selectedBonuses?: Map<string, Array<SelectedBonusType>>
    ): Promise<void> => {
        const data = await this.dataForPlaceBetNewTrading(false, selectedBonuses);

        if (data !== null) {
            console.info('Place bet with new data');
            this.handlePlaceBetRequest(fromFreeBets, data, undefined, selectedBonuses).catch((error) => {
                Logger.captureError('handlePlaceBetRequest error', 'Sportsbook', {
                    'Betslip info': {
                        data: JSON.stringify(data),
                    },
                });
                console.error('onPlaceBet error', error);
            });
            this.basicBetSlipState.onHideKeyboard();
        }
    };

    @computed public get isPlacingBet(): boolean {
        return this.placeBetStatus === 'IN_PROGRESS';
    }

    @action private onPlaceBetRequest = async (
        fromFreeBets: boolean,
        data: NewPlaceBetRequestType,
        isRab?: boolean,
        selectedBonuses?: Map<string, Array<SelectedBonusType>>
    ): Promise<void> => {
        this.placeBetStatus = 'IN_PROGRESS';

        const environmentState = EnvironmentState.get(this.common);

        const requestBody = preparePlaceBetRequestBody(data, this.configComponents.precision, selectedBonuses);
        if (requestBody === undefined || environmentState.envVariables.gatewayApiHost === null) {
            return;
        }

        const response: ParsedPlaceBetType = await postPlaceBet(
            environmentState.envVariables.gatewayApiHost,
            environmentState.envVariables.universe,
            requestBody
        );

        if (response.status === 'internalError') {
            this.placeBetStatus = 'ERROR';
            this.setPlaceBetErrorMsg('INTERNAL_SERVER_ERROR_MESSAGE');
            console.error(InternalPlaceBetErrorsMessages[response.data]);
            return;
        }

        if (response.status === 'success') {
            this.placeBetStatus = 'SUCCESS';
            const arrayBets: Array<ArrayBetsType> = response.data.flatMap((singleBet) => {
                const { id, type, totalStake, legs } = singleBet;
                if (id === undefined || id === null) {
                    return [];
                }

                const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                    legs[0]?.id
                );
                const arrayLegs: ArrayBetsType['legs'] = parsePlaceBetLegs(legs);

                return {
                    betId: id,
                    type,
                    totalStake,
                    totalStakeCurrency: this.sdk.currency,
                    legs: arrayLegs,
                    freebet: fromFreeBets,
                    isBoostVisible: selectionWithBoost?.selectionInfo?.reward !== undefined,
                    isBoosted:
                        selectionWithBoost?.selectionInfo?.reward !== undefined &&
                        selectionWithBoost.selectionInfo.reward.active,
                };
            });
            this.onGoogleTagManagerBetPlacedTag.trigger(arrayBets);
            this.onPlaceBetSuccess(fromFreeBets, response.data, isRab);
        }

        if (response.status !== 'error') {
            return;
        }

        this.placeBetStatus = 'ERROR';
        const placeBetErrors = parsePlaceBetErrors(response, this.basicBetSlipState.isShowQuickBet);
        placeBetErrors.forEach((error) => {
            switch (error.type) {
                case 'set-bet-to-high-value':
                    this.betTooHighValue =
                        error.maxStakePerLine === null
                            ? null
                            : (this.betTooHighValue = this.configComponents.precision.newFromOld(
                                  error.maxStakePerLine
                              ));
                    this.setPlaceBetErrorMsg(error.messageType);
                    break;
                case 'redirect':
                    this.onRedirectToBetslip();
                    break;
                case 'message':
                    this.setPlaceBetErrorMsg(error.messageType);
                    break;
            }
        });
    };

    //this method is copy from old onPlaceBetRequest. After tests remove old onPlaceBetRequest
    @action private handlePlaceBetRequest = async (
        fromFreeBets: boolean,
        data: NewPlaceBetRequestType,
        isRab?: boolean,
        selectedBonuses?: Map<string, Array<SelectedBonusType>>
    ): Promise<void> => {
        const environmentState = EnvironmentState.get(this.common);

        if (this.placeBetStatus === 'IN_PROGRESS') {
            return;
        }
        this.referralState.isFromFreeBet = fromFreeBets;
        this.placeBetStatus = 'IN_PROGRESS';
        if (environmentState.envVariables.isPlacebetNewVersion || this.localStorageState.newPlaceBet.getValue()) {
            await this.onPlaceBetRequest(fromFreeBets, data, isRab, selectedBonuses);
            return;
        }
        const response = await postPlaceBetNewTrading(data);
        if (response !== null && response.status === 'success' && response.data === null) {
            this.placeBetStatus = 'SUCCESS';
        }
        if (response !== null && response.status === 'success' && response.data !== null) {
            SymplifyState.get(this.common).registerCarmaConversion(parseInt(this.totalStake.value));
            this.placeBetStatus = 'SUCCESS';
            const arrayBets: Array<ArrayBetsType> = response.data.map((singleBet) => {
                const { id, type, totalStake, legs } = singleBet;
                const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                    legs[0]?.id
                );

                const arrayLegs = legs.map((singleLeg) => {
                    const { market, selection, event, price, sport } = singleLeg;
                    return {
                        marketId: market?.id,
                        marketName: market?.name,
                        selectionId: selection?.id,
                        selectionName: selection?.name,
                        betEventId: event.id,
                        betEventName: event.name,
                        price: price?.d,
                        sportId: sport?.id,
                        sportName: sport?.name,
                    };
                });
                return {
                    betId: id,
                    type: type,
                    totalStake: totalStake,
                    totalStakeCurrency: this.sdk.currency,
                    legs: arrayLegs,
                    freebet: fromFreeBets,
                    isBoostVisible: selectionWithBoost?.selectionInfo?.reward === undefined ? false : true,
                    isBoosted:
                        selectionWithBoost?.selectionInfo?.reward === undefined
                            ? false
                            : selectionWithBoost.selectionInfo.reward.active,
                };
            });

            this.onGoogleTagManagerBetPlacedTag.trigger(arrayBets);
            this.onPlaceBetSuccess(fromFreeBets, response.data, isRab);
        }
        if (response !== null && response.status === 'error' && response.data !== null && response.data !== undefined) {
            this.placeBetStatus = 'ERROR';
            for (const bet of response.data.bets) {
                if (bet.errors !== undefined && bet.errors.length > 0) {
                    const idNum = parseInt(bet.id, 10);
                    if (!isNaN(idNum)) {
                        await this.legsState.betslipData.onUpdateError(idNum, bet.errors);
                    }
                }
            }
        }
        if (response !== null && response.status === 'error' && response.data === undefined) {
            this.placeBetStatus = 'ERROR';

            const debug = response.debug ?? null;
            if (typeof debug === 'string') {
                this.setPlaceBetErrorMsg('INTERNAL_SERVER_ERROR_MESSAGE');
            } else {
                const errors = debug === null ? [] : debug.errors;
                const firstError = errors[0] ?? null;
                const firstErrorCode = firstError === null ? null : firstError.code;
                for (const error of errors) {
                    if (firstErrorCode === 'bet-stake-too-high') {
                        if (this.basicBetSlipState.isShowQuickBet) {
                            this.onRedirectToBetslip();
                        }
                        if (
                            error.code === 'bet-stake-too-high' &&
                            error.details !== undefined &&
                            error.details?.maxStakePerLine !== undefined &&
                            error.details.maxStakePerLine !== null &&
                            error.details.maxStakePerLine > 0
                        ) {
                            this.betTooHighValue = this.configComponents.precision.newFromOld(
                                error.details.maxStakePerLine
                            );
                            this.setPlaceBetErrorMsg('BET-TOO-HIGH');
                        } else if (
                            error.code === 'bet-stake-too-high' &&
                            error.details !== undefined &&
                            error.details?.maxStakePerLine !== undefined &&
                            error.details.maxStakePerLine === 0
                        ) {
                            this.betTooHighValue = this.configComponents.precision.newFromOld(
                                error.details.maxStakePerLine
                            );
                            this.setPlaceBetErrorMsg('MAX-BET-EXCEEDED');
                        } else if (error.code === 'bet-stake-too-high') {
                            this.betTooHighValue = null;
                            this.setPlaceBetErrorMsg('BET-TOO-HIGH');
                        }
                        if (error.code === 'bet-exceeds-max-payout') {
                            this.setPlaceBetErrorMsg('PAYOUT-TOO-HIGH');
                        }
                    }
                    if (error.code === 'minimum') {
                        this.setPlaceBetErrorMsg('BET-TOO-LOW');
                    }
                }
                for (const errorKey in response.errors) {
                    if (errorKey === 'potentialPayout') {
                        this.setPlaceBetErrorMsg('PAYOUT-TOO-HIGH');
                    }
                }
            }
        }

        //TODO - If the code is placed in this branch (e.g. because of error 500) then the ui freezes with the loading on the button
    };

    private convertSimpleToBets = (
        legs: Array<LegType>,
        combinations: Array<CastBetType>
    ): Array<NewTradingPostBetData> => {
        const bets = [];
        const legCombinations = [];

        for (const leg of legs) {
            if (leg.selectionId === null) {
                continue;
            }
            bets.push(
                Object.assign({
                    id: `${leg.selectionId}`,
                    legs: [
                        {
                            type: 'standard',
                            selection: { id: leg.selectionId },
                            market: { id: leg.marketId },
                            event: { id: leg.eventId },
                            price: leg.price,
                            priceType: leg.priceType,
                            uuid: leg.uuid,
                        },
                    ],
                })
            );

            legCombinations.push({
                type: 'standard',
                selection: { id: leg.selectionId },
                market: { id: leg.marketId },
                event: { id: leg.eventId },
                price: leg.price,
                priceType: leg.priceType,
                uuid: leg.uuid,
            });
        }

        for (const combination of combinations) {
            bets.push({
                id: `all${combination.type}`,
                legs: legCombinations,
            });
        }
        const newBets = this.possibleBetsRequestState.corePossibleBetsResponse?.bets ?? [];
        const mergedBets: Array<NewTradingPostBetData> = [];

        for (const newBet of newBets) {
            for (const bet of bets) {
                if (bet.id === newBet.id) {
                    newBet.legs = bet.legs;
                    //overwrite legs from possibleBetsResponse, to place bet need add more details of legs
                    //@ts-expect-error
                    mergedBets.push(newBet);
                }
            }
        }

        return mergedBets;
    };

    @action public clearError = (): void => {
        this.betTooHighValue = null;
        this.responseErrorMsgInner = [];
    };
    @action private setPlaceBetErrorMsg = (errorMsg: ErrorMsgType): void => {
        this.responseErrorMsgInner.push(errorMsg);
    };

    @computed public get responseErrorMsg(): Array<ErrorMsgType> {
        const tempErrorMsg = new Set(this.responseErrorMsgInner);
        return Array.from(tempErrorMsg);
    }

    @action public onPlaceBetSuccess = (
        fromFreeBets0: boolean,
        bets: PlaceBetBetsType | Array<SuccessPlaceBetResponseType>,
        isRab?: boolean
    ): void => {
        if (isRab === true) {
            this.rabState?.clearAllRabData();
            this.possibleBetsRequestState.prevCorePossibleBetsResponse = null;
        } else {
            this.onCleanAll();
            if (this.rabState !== null) {
                this.rabState.removeAllFromBetslip();
            }
        }

        if (this.sdk.session.userId !== null) {
            this.freeBetsData().refresh();
        }

        if (bets.length > 0) {
            let totalStake: Amount = new Amount('0');
            let totalPotentialReturns: Amount | null = new Amount('0');
            const fromFreeBets =
                this.freeBetsAmount !== null && this.freeBetsAmount.isGreaterThanZero() && fromFreeBets0;
            const isFreeBet: boolean = fromFreeBets
                ? fromFreeBets
                : bets.some((bet) => 'freebet' in bet && bet.freebet);

            const totalBonusEngineFreeBetsAmount = bets.reduce((acc, bet) => {
                if (bet.freebetCredits === undefined || bet.freebetCredits === null) {
                    return acc;
                }

                const betFreeBetSum = bet.freebetCredits.reduce(
                    (freeBetAcc, freeBet) =>
                        freeBetAcc.add(this.configComponents.precision.newFromAnything(freeBet.amount)),

                    new Amount('0')
                );
                return acc.add(betFreeBetSum);
            }, new Amount('0'));

            const isBonusEngineFreeBet =
                this.configComponents.config.bonuseEngine && totalBonusEngineFreeBetsAmount.isGreaterThanZero();

            const isSp = bets.some((bet) => bet.legs.some((leg) => leg.priceType === 'sp'));

            bets.forEach(
                (bet) => (totalStake = totalStake.add(this.configComponents.precision.newFromAnything(bet.totalStake)))
            );

            if (isSp) {
                totalPotentialReturns = null;
            } else {
                for (const bet of bets) {
                    if (bet.potentialReturns !== undefined && bet.potentialReturns !== null) {
                        totalPotentialReturns = totalPotentialReturns.add(new Amount(bet.potentialReturns));
                    }
                }
            }

            const betsDetails: Array<BetDetailsLegsType> = [];
            for (const bet of bets) {
                if (bet.type === 'SGL' && bet.id !== undefined && bet.id !== null) {
                    const firstLeg = bet.legs[0] ?? null;
                    if (firstLeg !== null) {
                        const marketName =
                            'market' in firstLeg &&
                            firstLeg.market !== undefined &&
                            firstLeg.market !== null &&
                            'name' in firstLeg.market
                                ? firstLeg.market.name
                                : 'Market name';
                        const selectionName =
                            'selection' in firstLeg && firstLeg.selection !== undefined
                                ? firstLeg.selection.name
                                : 'Selection name';
                        betsDetails.push({
                            potentialReturns: bet.potentialReturns ?? null,
                            stake: bet.stakePerLine,
                            betId: bet.id,
                            priceBet: firstLeg.price?.f ?? null,
                            eventName: firstLeg.event.name,
                            marketName,
                            selectionName,
                        });
                    }
                }
            }

            this.betReceiptSummary = {
                totalBonusEngineFreeBetsAmount:
                    this.configComponents.config.bonuseEngine && totalBonusEngineFreeBetsAmount.isGreaterThanZero()
                        ? totalBonusEngineFreeBetsAmount
                        : null,
                totalCashStake:
                    this.configComponents.config.bonuseEngine && totalBonusEngineFreeBetsAmount.isGreaterThanZero()
                        ? totalStake.sub(totalBonusEngineFreeBetsAmount)
                        : null,
                totalStake,
                totalPotentialReturns: totalPotentialReturns ?? new Amount('0'),
                isFreeBet: isFreeBet || isBonusEngineFreeBet,
                details: {
                    legs: betsDetails,
                },
            };
        } else {
            this.betReceiptSummary = null;
        }

        setTimeout(() => {
            this.betReceiptSummary = null;
            // if (fromFreeBets0) {
            //     this.possibleBetsRequestState.onChangeFreeBetOption();
            // }
        }, 3000);

        if (this.sdk.session.userId !== null) {
            this.freeBetsData().refresh();
        }
    };

    @action public onClearMessage = (): void => {
        this.betReceiptSummary = null;
    };

    @computed public get totalStakeRab(): Amount {
        let sum = new Amount('0');

        for (const item of this.possibleBetsRequestState.coreRabPossibleBetsResponse) {
            const stakePerLine = item.stakePerLine ?? 0;
            sum = sum.add(this.configComponents.precision.newFromAnything(stakePerLine));
        }

        return sum;
    }

    @computed public get payoutRab(): Amount {
        let sum = new Amount('0');
        const bets =
            this.possibleBetsRequestState.corePossibleBetsResponse?.bets.filter((bet) => bet.type === 'RAB') ?? [];
        for (const bet of bets) {
            const payout = bet.potentialReturns ?? '0';
            const payoutAmount = new Amount(payout);
            sum = sum.add(payoutAmount);
        }
        return sum;
    }

    @computed public get totalStake(): Amount {
        return this.totalStakeClassic.totalStake;
    }

    @computed public get totalStakeWithStakeBonusToSubstract(): {
        totalStake: Amount;
        stakeToSubstractAfterBonus: Amount;
    } {
        return this.totalStakeClassic;
    }

    @computed private get totalStakeClassic(): { totalStake: Amount; stakeToSubstractAfterBonus: Amount } {
        let everyPossibleBet = [];
        everyPossibleBet =
            this.referralState.referralData === null
                ? this.possibleBetsRequestState.castBets
                : this.referralState.totalStake;
        let totalStake = new Amount('0');
        let stakeToSubstractAfterBonus = new Amount('0');

        for (const bet of everyPossibleBet) {
            const stake =
                bet.stakePerLine === null
                    ? new Amount('0')
                    : this.configComponents.precision.newFromAnything(bet.stakePerLine);

            if (this.common.features.bonuseEngine && bet.id !== null) {
                const existingSelectedBonuses = this.selectedBonuses.get(bet.id);

                if (existingSelectedBonuses !== undefined && existingSelectedBonuses.length > 0) {
                    const totalStakeAfterBonuses = stake.sub(
                        existingSelectedBonuses.reduce((acc, value) => acc.add(value.currentAmount), new Amount('0'))
                    );

                    if (totalStakeAfterBonuses.isLessOrEqual(new Amount('0'))) {
                        stakeToSubstractAfterBonus = stakeToSubstractAfterBonus.add(stake);
                    }
                }
            }

            let multiplier = bet.eachWay !== null && bet.eachWay === true ? 2 : 1;
            multiplier *= bet.numLines === null ? 1 : bet.numLines;
            totalStake = totalStake.add(stake.multiply(multiplier));
        }

        return { totalStake: totalStake.add(this.totalStakeRab), stakeToSubstractAfterBonus };
    }

    @computed public get potentialReturn(): Amount {
        if (this.referralState.referralData === null) {
            const potentialReturnCombinations = this.combinationState.forPotentialReturn;
            const potentialReturnLegs = this.legsState.forPotentialReturn;
            return potentialReturnCombinations.add(potentialReturnLegs.add(this.payoutRab));
        } else {
            return this.referralState.totalPotentialReturn;
        }
    }

    @computed public get requiredToBetAmount(): Amount | null {
        if (this.basicBetSlipState.betSlipPlayableBalanceAmounts !== null) {
            const { requiredAmount, currentAmount } = this.basicBetSlipState.betSlipPlayableBalanceAmounts;
            if (
                requiredAmount !== null &&
                requiredAmount !== undefined &&
                currentAmount !== null &&
                currentAmount !== undefined
            ) {
                const required = this.configComponents.precision.newFromAnything(requiredAmount);
                const current = this.configComponents.precision.newFromAnything(currentAmount);

                const remainingRequired = required.sub(current);

                return remainingRequired;
            }
        }
        return null;
    }

    @computed public get isPlayableBalanceWarning(): boolean {
        return this.requiredToBetAmount !== null;
    }

    @computed public get isSinglesOnlyWarning(): boolean {
        return this.basicBetSlipState.betSlipSinglesOnly;
    }

    @computed private get rabValues(): RabItemState[] {
        return this.rabState === null ? [] : this.rabState.activeBets;
    }

    @computed private get legsIds(): Array<BetslipSingleId> {
        return this.getLegsIds();
    }

    @computed private get placeBetStatusIsNull(): boolean {
        return (
            this.basicBetSlipState.placeBetStatus === null || this.basicBetSlipState.placeBetStatus !== 'IN_PROGRESS'
        );
    }

    @computed public get isLegsChangedWarning(): boolean {
        if (this.referralState.isReferred) {
            return false;
        }
        let anyLegChanged = false;
        let anyRabLegChanged = false;

        this.rabValues.map((rabVal: RabItemState) => {
            if (rabVal.selectionChanged) {
                anyRabLegChanged = true;
            }
        });

        for (const legId of this.legsIds) {
            const leg = legId.getModel();
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (leg !== null) {
                if (anyLegChanged === false) {
                    anyLegChanged = leg.selectionChanged && this.placeBetStatusIsNull;
                }
            }
        }
        return anyLegChanged || anyRabLegChanged;
    }

    @computed private get isDisabledBettingButton(): boolean {
        return (
            this.isLegsChangedWarning ||
            this.referralState.isReferredBetMessage ||
            this.referralState.isRejectedByTrader ||
            this.referralState.isRejectedByCustomer ||
            this.referralState.isBetSlipOffer ||
            this.totalStake.isEqualWith(new Amount('0')) ||
            this.legsState.isError ||
            this.combinationState.isError
        );
    }

    @computed public get betReceipt(): BetReceiptType | null {
        return this.betReceiptSummary;
    }

    @computed public get balanceAfter(): Amount {
        const { balance } = this.basicBetSlipState.userData;

        if (balance === null) {
            return new Amount('0');
        }

        if (this.common.features.bonuseEngine) {
            const { totalStake, stakeToSubstractAfterBonus } = this.totalStakeWithStakeBonusToSubstract;

            const stakeToSubstract = totalStake.sub(stakeToSubstractAfterBonus);

            return balance.sub(stakeToSubstract);
        }

        return balance.sub(this.totalStake);
    }

    @computed public get isAnyNA(): boolean {
        const referralWithSP = (this.referralState.referralData === null ? [] : this.referralState.totalStake).some(
            (elem) => elem.priceType === 'sp'
        );
        const castBetsWithoutPotentialReturns = (
            this.referralState.referralData === null ? this.possibleBetsRequestState.castBets : []
        ).some((elem) => elem.potentialReturns === null);

        return referralWithSP || castBetsWithoutPotentialReturns;
    }
}
